import React from 'react'

function ArrowForward({ arrowFill, className }) {
  return (
    <svg width={25.992} height={25.992} viewBox='0 0 25.992 25.992' className={className}>
      <path d='M0 0h25.992v25.992H0z' fill='none' />
      <path
        d='M13 1.3l-2.065 2.061 8.158 8.173H1.3v2.924h17.793l-8.158 8.173L13 24.692l11.7-11.7z'
        fill={arrowFill}
      />
    </svg>
  )
}

export default ArrowForward
