import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// Reducers
import app from './app'
import stations from './stations'
import journeys from './journeys'
import bookings from './bookings'
import railcards from './railcards'
import tocs from './tocs'

// Here we'll combine all the reducer in our app
const createRootReducer = (history) => combineReducers({
  app,
  stations,
  journeys,
  bookings,
  railcards,
  tocs,
  router: connectRouter(history)
})

export default createRootReducer
