import axios from 'axios'

import {
  SET_RAILCARDS
} from './constants'

// Selectors
import { selectAPIUrl, selectAPIToken } from '../selectors/app'

// Data
import localRailcards from '../assets/offline-data/railcards.json'
export const setRailcards = (railcards) => (dispatch) => dispatch({
  type: SET_RAILCARDS,
  railcards
})

export const getRailcards = () => (dispatch, getState) => {
  // Until the cache issue is resolved
  const filteredRailcards = localRailcards
      .filter((railcard) => railcard.attributes.program.search('UK') !== -1)
      .map((railcard) => railcard.attributes)
  return dispatch(setRailcards(filteredRailcards))

  const token = selectAPIToken(getState())
  const url = selectAPIUrl(getState())
  axios.get(`${url}/v1/railcards`, {
    headers: { Authorization: token }
  }).then(({ data }) => {
    // Remove useless railcards
    // Perhaps this will live in the BE in the future so it can be cached
    const filteredRailcards = data
      .filter((railcard) => railcard.attributes.program.search('UK') !== -1)
      .map((railcard) => railcard.attributes)

    dispatch(setRailcards(filteredRailcards))
  })
}
