import axios from 'axios'
import { formatISO } from 'date-fns'
import { initialState } from '../reducers/journeys'

// Selectors
import { selectAPIUrl, selectAPIToken } from '../selectors/app'
import {
  selectInboundMoment,
  selectOutboundMoment,
  selectDestinationStation,
  selectOriginStation,
  selectIsReturnJourney,
  selectRailcard,
  selectPassengerAdult,
  selectCheaperFaresFromSelectedJourneys
} from '../selectors/journeys'
import { selectRailcardByDescription } from '../selectors/railcards'
import { appOpenBookingOverview, appOpenCheaperFareFound } from './app'
import { createBooking } from './bookings'

import {
  SET_HOME_JOURNEYS, SET_ORIGIN_STATION, SET_DESTINATION_STATION,
  SET_INBOUND_MOMENT, SET_OUTBOUND_MOMENT, SET_IS_RETURN_JOURNEY,
  SET_IS_LOADING_JOURNEY_RESULTS, SET_JOURNEY_RESULTS,
  SET_PASSENGER_ADULT, SET_RAILCARD, SET_RAILCARD_PHASE, SET_SELECTED_ITEM,
  SET_PICKING_TYPE, SET_NO_JOURNEYS_FOUND, QUICK_RETURN_JOURNEY_PICKED, RESET_SELECTED_ITEM, SET_CHEAPER_FARES_FOUND,
} from './constants'

// Actions
export const setOriginStation = (station) => (dispatch) => dispatch({
  type: SET_ORIGIN_STATION,
  station
})

export const setDestinationStation = (station) => (dispatch) => dispatch({
  type: SET_DESTINATION_STATION,
  station
})

export const setInboundMoment = (moment) => (dispatch) => dispatch({
  type: SET_INBOUND_MOMENT,
  moment
})

export const setOutboundMoment = (moment) => (dispatch) => dispatch({
  type: SET_OUTBOUND_MOMENT,
  moment
})

export const resetMoments = () => (dispatch) => {
  dispatch(setOutboundMoment(initialState.outboundMoment))
  dispatch(setInboundMoment(initialState.inboundMoment))
}

export const setIsReturnJourney = (isReturnJourney) => (dispatch) => dispatch({
  type: SET_IS_RETURN_JOURNEY,
  isReturnJourney
})

export const setIsLoadingJourneyResults = (isLoadingResults) => (dispatch) => dispatch({
  type: SET_IS_LOADING_JOURNEY_RESULTS,
  isLoadingResults
})

export const setJourneyResults = (results) => (dispatch) => dispatch({
  type: SET_JOURNEY_RESULTS,
  results
})

export const setRailcardPhase = (railcardPhase) => (dispatch) => dispatch({
  type: SET_RAILCARD_PHASE,
  railcardPhase
})

export const setPassengerAdult = (passenger) => (dispatch) => dispatch({
  type: SET_PASSENGER_ADULT,
  passenger
})

export const setRailcard = (railcard) => (dispatch) => dispatch({
  type: SET_RAILCARD,
  railcard
})

export const setCurrentlyPicking = (type, openReturnID = null) => (dispatch) => dispatch({
  type: SET_PICKING_TYPE,
  currentlyPicking: type,
  openReturnID
})

export const setNoJourneysFound = (found) => (dispatch) => dispatch({
  type: SET_NO_JOURNEYS_FOUND,
  noJourneysFound: found
})


export const setSelectedItem = (type, legSolutionID, priceID) => (dispatch) => dispatch({
  type: SET_SELECTED_ITEM,
  selectedItem: {
    [type]: {
      legSolutionID,
      priceID
    }
  }
})

export const resetSelectedItems = () => (dispatch) => dispatch({
  type: RESET_SELECTED_ITEM
})

export const setQuickReturnJourneyPicked = (status) => (dispatch) => dispatch({
  type: QUICK_RETURN_JOURNEY_PICKED,
  status
})

export const setCheaperFaresFound = (cheaperFares) => (dispatch) => dispatch({
  type: SET_CHEAPER_FARES_FOUND,
  cheaperFares
})

export const findCheaperFares = (dispatch, getState) => {
  const state = getState()
  const cheaperFaresFound = selectCheaperFaresFromSelectedJourneys(state)
  dispatch(setCheaperFaresFound(cheaperFaresFound))

  if(cheaperFaresFound !== null) {
    dispatch(appOpenCheaperFareFound())
  } else {
    dispatch(createBooking())
    dispatch(appOpenBookingOverview())
  }
}

// Thunks

export const getJourneyResults = () => (dispatch, getState) => {
  const token = selectAPIToken(getState())
  const url = selectAPIUrl(getState())

  const originStation = selectOriginStation(getState())
  const destinationStation = selectDestinationStation(getState())

  const inboundMoment = selectInboundMoment(getState())
  const outboundMoment = selectOutboundMoment(getState())

  const isReturn = selectIsReturnJourney(getState())

  const passengerAdult = selectPassengerAdult(getState())

  const railcard = selectRailcard(getState())
  const railcardValue = selectRailcardByDescription(railcard)(getState())
  dispatch(setNoJourneysFound(false))
  dispatch(setIsLoadingJourneyResults(true))
  axios.get(`${url}/v1/journeys`, {
    params: {
      origin: originStation.value,
      destination: destinationStation.value,
      outboundDepartureDateTime: outboundMoment.departAfter ? formatISO(new Date(outboundMoment.dateTime)) : null,
      outboundArrivalDateTime: !outboundMoment.departAfter ? formatISO(new Date(outboundMoment.dateTime)) : null,
      return: isReturn,
      // Only send return values if it is indeed a return
      ...isReturn && {
        returnDepartureDateTime: inboundMoment.departAfter ? formatISO(new Date(inboundMoment.dateTime)) : null,
        returnArrivalDateTime: !inboundMoment.departAfter ? formatISO(new Date(inboundMoment.dateTime)) : null
      },
      ...passengerAdult && {
        adults: 1
      },
      ...!passengerAdult && {
        children: 1
      },
      fareQualifiers: railcardValue?.program,
      // Suggested by Ben Fenn in SD-24879
      fareFilter: 'CHEAPEST_ONEWAY_AND_ROUNDTRIP'
    },
    headers: {
      Authorization: token
    }
  }).then(({ data }) => {
    dispatch(setJourneyResults(data))
    dispatch(setIsLoadingJourneyResults(false))
  })
    .catch((data, a) => {
      if (data?.response?.status === 404 || data.code === 404 || data.code === 400) {
        dispatch(setNoJourneysFound(true))
      }
      dispatch(setJourneyResults(null))
      dispatch(setIsLoadingJourneyResults(false))
    })
}
