import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'

// Styles
import styles from './App.module.scss'

// Actions
import { appGetToken, appInitialize, appOpenHome } from '../../actions/app'

// Selectors
import { selectCurrentPath, selectTypeOfStationPickerSelection } from '../../selectors/app'

// Components
import Footer from '../Footer/Footer'
import Clock from '../Clock/Clock'
import Header from '../theme/Header/Header'
import { selectIsCurrentlyPicking } from '../../selectors/journeys'

function App ({
  children
}) {
  // Here we get dispatch from a hook
  const dispatch = useDispatch()
  // Now we're using the useEffect hook as if it was componentDidMount
  // read more about this hook here: https://reactjs.org/docs/hooks-effect.html
  useEffect(() => {
    dispatch(appGetToken()).then(() => dispatch(appInitialize))
  }, [dispatch])

  const path = useSelector(selectCurrentPath)
  const typeOfSelection = useSelector(selectTypeOfStationPickerSelection)
  const { currentlyPicking } = useSelector(selectIsCurrentlyPicking)

  const headerTexts = {
    '/home': {
      headerText: 'BUY eTICKETS*'
    },
    '/station-picker': {
      headerText: typeOfSelection === 'destination' ? 'WHERE ARE YOU GOING?' : 'WHERE ARE YOU COMING FROM?'
    },
    '/pre-search': {
      headerText: 'WHERE ARE YOU GOING?'
    },
    '/journey-results': {
      headerText: currentlyPicking === 0 ? 'SELECT OUTWARD' : 'SELECT RETURN'
    },
    '/ticket': {
      headerText: 'COMPLETE PURCHASE'
    }
  }

  const { headerText = '' } = headerTexts[path] || []

  const handleOnIdle = event => {
    console.log('user timedout')
    dispatch(appOpenHome())
    window.parent.postMessage('exit', '*')
  }

  useIdleTimer({
    timeout: 30000,
    onIdle: handleOnIdle,
    debounce: 500
  })

  return (
    <div className={styles.frame}>
      <Clock />
      {headerText && <Header>{headerText}</Header>}
      {children}
      <Footer />
    </div>
  )
}

export default App
