import { collect } from 'collect.js'
import { createSelector } from 'reselect'

export const selectIsCreatingBooking = (state) => state.bookings.isCreatingBooking

export const selectErrorCreatingBooking = (state) => state.bookings.errorCreatingBooking

export const selectBooking = (state) => state.bookings.booking || null

export const selectBookingOrder = createSelector(
  selectBooking,
  (booking) => booking?.orders?.order[0] || null
)

export const selectBookingTotalPrice = createSelector(
  selectBooking,
  (booking) => booking?.paymentRequirements?.minimumDepositAmount?.$value || null
)

export const selectBookingHandoffQRCode = createSelector(
  selectBooking,
  (booking) => booking?.qrCode || null
)

export const selectBookingTravelSegments = createSelector(
  selectBookingOrder,
  (order) => order?.travelSegments.travelSegment
)

export const selectBookingTrainTravelSegments = createSelector(
  selectBookingTravelSegments,
  (travelSegments) => travelSegments?.filter(travelSegment => travelSegment.attributes.type === 'TRAIN')
)

export const selectBookingTravelSegmentByID = (travelSegmentIDRef) => createSelector(
  selectBookingTrainTravelSegments,
  (travelSegments) => travelSegments?.find(travelSegment => travelSegment.attributes.travelSegmentID === travelSegmentIDRef)
)

export const selectBookingTicketableFares = createSelector(
  selectBookingOrder,
  (order) => order?.ticketableFares?.ticketableFare || []
)

export const selectBookingTicketableFareByTravelSegmentID = (travelSegmentID) => createSelector(
  selectBookingTicketableFares,
  (ticketableFares) => ticketableFares.find(ticketableFare =>
    ticketableFare.passengerReferences.passengerReference[0].fareCodes.fareCode.find(fareCode =>
      fareCode.travelSegmentIDRef === travelSegmentID) !== undefined
  )
)

export const selectBookingTicketableFare = (ticketableFareID) => createSelector(
  selectBookingOrder,
  (order) => order?.ticketableFares?.ticketableFare.find(ticketableFare => ticketableFare.attributes.ticketableFareID === ticketableFareID) || null
)

export const selectBookingPassengerReference = (ticketableFareID) => createSelector(
  selectBookingTicketableFare(ticketableFareID),
  (ticketableFare) => ticketableFare?.passengerReferences?.passengerReference[0] || null
)

export const selectBookingFare = (ticketableFareID) => createSelector(
  selectBookingPassengerReference(ticketableFareID),
  (passengerReference) => passengerReference?.fareCodes.fareCode[0] || null
)

export const selectBookingPrices = (ticketableFareID) => createSelector(
  selectBookingTicketableFare(ticketableFareID),
  (ticketableFare) => ticketableFare?.prices?.price || []
)

export const selectBookingTrainTravelSegmentsByTicketableFareID = (ticketableFareID) => createSelector(
  selectBookingPassengerReference(ticketableFareID),
  selectBookingTrainTravelSegments,
  (passengerReference, travelSegments) => {
    const fares = passengerReference?.fareCodes.fareCode
    const travelSegmentIDs = fares.map(fare => fare.travelSegmentIDRef)
    return travelSegments.filter(travelSegment => travelSegmentIDs.includes(travelSegment.attributes.travelSegmentID))
  }
)

export const selectBookingTravelSegmentsByTicketableFareID = (ticketableFareID) => createSelector(
  selectBookingPassengerReference(ticketableFareID),
  selectBookingTravelSegments,
  (passengerReference, travelSegments) => {
    const fares = passengerReference?.fareCodes.fareCode
    const travelSegmentIDs = fares.map(fare => fare.travelSegmentIDRef)
    return travelSegments.filter(travelSegment => travelSegmentIDs.includes(travelSegment.attributes.travelSegmentID))
  }
)

export const selectBookingOrderedLegTicketableFares = createSelector(
  selectBookingTrainTravelSegments,
  selectBookingTicketableFares,
  (travelSegments, ticketableFares) => {
    return collect(travelSegments
      ?.sort((a, b) => a.attributes.legGrouping - b.attributes.legGrouping)
      ?.filter(travelSegment => ticketableFares.filter(ticketableFare =>
        ticketableFare.passengerReferences.passengerReference[0]
        .fareCodes.fareCode.filter(fareCode => fareCode.travelSegmentIDRef === travelSegment.attributes.travelSegmentID)
      ))
      ?.map(travelSegment => ({
          legGrouping: travelSegment.attributes.legGrouping,
          ...ticketableFares.find(ticketableFare => ticketableFare.passengerReferences.passengerReference[0].fareCodes.fareCode.find(fareCode => fareCode.travelSegmentIDRef === travelSegment.attributes.travelSegmentID))
        })
      )).unique(ticketableFare => ticketableFare.attributes.ticketableFareID + ticketableFare.legGrouping).toArray()
  }
)
