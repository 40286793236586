import React from 'react'
import styles from './SecondaryHeader.module.scss'

export default function SecondaryHeader({ children }) {
  return (
    <div>
      <div className={styles.SecondaryHeader}>
        {children}
      </div>
      <div className={styles.divider} />
    </div>
  )
}
