import React, { useState, useEffect, useMemo } from 'react'
import Picker from 'react-mobile-picker'
import { eachDayOfInterval, addDays, format } from 'date-fns'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './DateTimePicker.module.scss'

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h)
  return this
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedDateTime: PropTypes.object,
  departAfter: PropTypes.bool,
  currentlyPicking: PropTypes.string
}

export function possibleHours (selectedDate, minDate, currentlyPicking) {
  if (selectedDate === 'Today' && currentlyPicking === 'outbound') {
    return Array(24 - (format(new Date(), 'H'))).fill(0).map((_, i) => { return ('0' + ~~((i + Number(format(new Date(), 'H'))))).replace(/\d(\d\d)/g, '$1') })
  } else if ((selectedDate === 'Today' || selectedDate === format(minDate, 'E d MMM')) && currentlyPicking === 'return') {
    return Array(24 - (format(new Date(minDate).addHours(2), 'H'))).fill(0).map((_, i) => { return ('0' + ~~((i + Number(format(new Date(minDate).addHours(2), 'H'))))).replace(/\d(\d\d)/g, '$1') })
  }

  return Array(24).fill(0).map((_, i) => { return ('0' + ~~(i)).replace(/\d(\d\d)/g, '$1') })
}
export function possibleMinutes (selectedDate, minDate, currentlyPicking) {
  return Array(4).fill(0).map((_, i) => { return ('0' + 60 * (i / 4 % 1)).replace(/\d(\d\d)/g, '$1') })
}
function DateTimePicker ({
  onChange,
  selectedDateTime,
  departAfter,
  currentlyPicking,
  minDate
}) {
  const [selectedDateTimes, setSelectedDateTimes] = useState(selectedDateTime)
  const [selectedDepartAfter, setSelectedDepartAfter] = useState(departAfter)
  const handleDateTimeChange = (name, value) => {
    setSelectedDateTimes({ ...selectedDateTimes, [name]: value })
    dateTimes.hour = possibleHours(selectedDateTimes.date, minDate, currentlyPicking)
    dateTimes.minute = possibleMinutes(selectedDateTimes)
  }

  const generatePossibleDates = useMemo(() => {
    if (currentlyPicking === 'return') {
      return eachDayOfInterval({ start: new Date(minDate), end: addDays(new Date(), 84) })
        .map(date => format(new Date(), 'E d MMM') === format(date, 'E d MMM') ? 'Today' : format(date, 'E d MMM'))
    }

    return eachDayOfInterval({ start: new Date(), end: addDays(new Date(minDate), 84) })
      .map(date => format(new Date(), 'E d MMM') === format(date, 'E d MMM') ? 'Today' : format(date, 'E d MMM'))
  }, [minDate, currentlyPicking])

  const dateTimes = useMemo(() => ({
    date: generatePossibleDates,
    hour: Array(24).fill(0).map((_, i) => { return ('0' + ~~(i)).replace(/\d(\d\d)/g, '$1') }),
    minute: Array(4).fill(0).map((_, i) => { return ('0' + 60 * (i / 4 % 1)).replace(/\d(\d\d)/g, '$1') })
  }), [generatePossibleDates])

  useEffect(() => {
    dateTimes.hour = possibleHours(selectedDateTimes.date, minDate, currentlyPicking)
    dateTimes.minute = possibleMinutes(selectedDateTimes)
    onChange(selectedDateTimes, selectedDepartAfter)
  }, [selectedDateTimes, selectedDepartAfter])

  return (
    <div className={styles.datePickerScreen}>
      <h2>SELECT {currentlyPicking.toUpperCase()}</h2>
      <div className={styles.toggleOptionsContainer}>
        <div onClick={() => setSelectedDepartAfter(true)} className={classNames(styles.toggleOption, selectedDepartAfter ? styles.selected : null)}>
          Depart after
        </div>
        <div onClick={() => setSelectedDepartAfter(false)} className={classNames(styles.toggleOption, !selectedDepartAfter ? styles.selected : null)}>
          Arrive by
        </div>
      </div>
      <div className={styles.datePickerContainer}>
        <Picker
          itemHeight={70}
          height={670}
          optionGroups={dateTimes.date ? dateTimes : null}
          valueGroups={selectedDateTimes}
          onChange={handleDateTimeChange}
        />
      </div>
    </div>
  )
}

DateTimePicker.propTypes = propTypes

export default DateTimePicker
