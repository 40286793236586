import React, { useCallback } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { format, utcToZonedTime } from 'date-fns-tz'

// Styles
import styles from './RouteInfoModal.module.scss'

// Actions
import { routeInfoCloseModal } from '../../actions/app'

// Selectors
import { selectRouteInfoModalVisible } from '../../selectors/app'
import { selectStationByCode } from '../../selectors/stations'
import { parseISO } from 'date-fns'
import { selectTOCByMarketingCarrier } from '../../selectors/tocs'
import { parseISO8601Duration } from '../../utils'


const Pedestrian = ({ travelSegment, duration }) => (
  <>
    <li className={styles.walkingDirection}>Walking Direction {duration}</li>
    <li className={styles.departure}>
      <span>{format(utcToZonedTime(travelSegment.departureDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})}</span>
      <span>{useSelector(selectStationByCode(travelSegment.originTravelPoint.$value)).label}</span>
    </li>
    <li className={styles.arrival}>
      <span>{format(parseISO(travelSegment.arrivalDateTime), 'HH:mm')}</span>
      <span>{useSelector(selectStationByCode(travelSegment.destinationTravelPoint.$value)).label}</span>
    </li>
  </>
)

const Train = ({ travelSegment, duration }) => (
  <>
    <li className={styles.trainDirection}>Train ({useSelector(selectTOCByMarketingCarrier(travelSegment.marketingCarrier))?.attributes?.name}) {duration}</li>
    <li className={styles.departure}>
    <span>{format(utcToZonedTime(travelSegment.departureDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})}</span>
      <span>{useSelector(selectStationByCode(travelSegment.originTravelPoint.$value)).label}</span>
    </li>
    <li className={styles.arrival}>
    <span>{format(utcToZonedTime(travelSegment.arrivalDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})}</span>
      <span>{useSelector(selectStationByCode(travelSegment.destinationTravelPoint.$value)).label}</span>
    </li>
  </>
)

const Generic = ({ travelSegment, duration }) => (
  <>
    <li className={styles.genericDirection}>{travelSegment.equipmentType.$value} Direction {duration}</li>
    <li className={styles.departure}>
    <span>{format(utcToZonedTime(travelSegment.departureDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})}</span>
      <span>{useSelector(selectStationByCode(travelSegment.originTravelPoint.$value)).label}</span>
    </li>
    <li className={styles.arrival}>
    <span>{format(utcToZonedTime(travelSegment.arrivalDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})}</span>
      <span>{useSelector(selectStationByCode(travelSegment.destinationTravelPoint.$value)).label}</span>
    </li>
  </>
)

export function Modal ({
  travelSegments
}) {
  const dispatch = useDispatch()
  const isModalVisible = useSelector(selectRouteInfoModalVisible)

  const handleCloseModal = useCallback(() => dispatch(routeInfoCloseModal()), [dispatch])

  return (
    <div className={classNames(styles.modal, { [styles.visible]: isModalVisible })}>
      <h1 className={styles.header}>Journey Info</h1>
      <ul className={styles.travelSegments}>
        {travelSegments.map(travelSegment => {
          const travelSegmentType = travelSegment.attributes.type
          const { hours, minutes } = parseISO8601Duration(travelSegment.duration)
          const duration = `${hours}h ${minutes}m`
          switch (travelSegmentType) {
            case 'TRANSFER':
              switch (travelSegment.equipmentType.$value) {
                case 'Pedestrian':
                  return <Pedestrian travelSegment={travelSegment} duration={duration}/>
                default:
                  return <Generic travelSegment={travelSegment} duration={duration}/>
              }
            case 'TRAIN':
              return <Train travelSegment={travelSegment} duration={duration}/>
            default:
              return <Generic travelSegment={travelSegment} duration={duration}/>
          }
        })}
      </ul>
      <button className={styles.button} onClick={handleCloseModal}>
        Close
      </button>
    </div>
  )
}

export default Modal
