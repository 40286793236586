import axios from 'axios'

import {
  SET_STATIONS
} from './constants'

// Selectors
import { selectAPIUrl, selectAPIToken } from '../selectors/app'

// Data
import crs from '../assets/crs.json'
import localStations from '../assets/offline-data/stations.json'

export const setStations = (stations) => (dispatch) => dispatch({
  type: SET_STATIONS,
  stations
})

export const getStations = () => (dispatch, getState) => {
  // Until the cache issue is resolved
  const filteredStations = localStations
    .map((station) => ({
      value: station.attributes.code,
      label: station.attributes.name,
      type: station.attributes.itineraryStation,
      crs: crs[station.attributes.name.replace('+', ' ')]
    }))
  return dispatch(setStations(filteredStations))
  
  const token = selectAPIToken(getState())
  const url = selectAPIUrl(getState())
  axios.get(`${url}/v1/stations`, {
    headers: { Authorization: token }
  }).then(({ data }) => {
    // Remove useless stations
    // Perhaps this will live in the BE in the future so it can be cached
    const filteredStations = data
      // .filter((station) => station.attributes.itineraryStation === 'YES')
      .map((station) => ({
        value: station.attributes.code,
        label: station.attributes.name,
        type: station.attributes.itineraryStation,
        crs: crs[station.attributes.name.replace('+', ' ')]
      }))

    dispatch(setStations(filteredStations))
  })
}
