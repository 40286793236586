import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Styles
import styles from './RoundButton.module.scss'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'large']),
  children: PropTypes.node,
  type: PropTypes.oneOf(['regular', 'home', 'back', 'cancel', 'confirm']),
  align: PropTypes.oneOf(['right', 'left'])
}

function RoundButton ({
  onClick,
  className = '',
  disabled = false,
  size = 'normal',
  children = null,
  type = 'regular',
  align = 'left'
}) {
  return (
    <button
      className={classNames(
        styles.button,
        styles[size],
        styles[type],
        styles[align],
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

RoundButton.propTypes = propTypes

export default RoundButton
