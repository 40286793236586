export const flexibleFares = [
  'ADVANCE_DAY_OPEN_RETURN',
  'ADVANCE_OPEN_RETURN',
  'ANYTIME',
  'ANYTIME_DAY',
  'ANYTIME_DAY_OPEN_RETURN',
  'ANYTIME_DAY_OPEN_TRAVELCARD',
  'ANYTIME_DAY_RETURN',
  'ANYTIME_DAY_TRAVELCARD',
  'ANYTIME_OPEN_RETURN',
  'ANYTIME_OPEN_TRAVELCARD',
  'ANYTIME_RETURN',
  'ANYTIME_TRAVELCARD',
  'SUPER_OFFPEAK_DAY',
  'SUPER_OFFPEAK_DAY_OPEN_RETURN',
  'SUPER_OFFPEAK_DAY_OPEN_TRAVELCARD',
  'SUPER_OFFPEAK_DAY_RETURN',
  'SUPER_OFFPEAK_DAY_TRAVELCARD',
  'SUPER_OFFPEAK_OPEN_RETURN',
  'SUPER_OFFPEAK_OPEN_TRAVELCARD',
  'SUPER_OFFPEAK_RETURN',
  'SUPER_OFFPEAK_TRAVELCARD',
  'OFFPEAK',
  'OFFPEAK_DAY',
  'OFFPEAK_DAY_OPEN_RETURN',
  'OFFPEAK_DAY_OPEN_TRAVELCARD',
  'OFFPEAK_DAY_RETURN',
  'OFFPEAK_DAY_TRAVELCARD',
  'OFFPEAK_OPEN_RETURN',
  'OFFPEAK_OPEN_TRAVELCARD',
  'OFFPEAK_RETURN',
  'OFFPEAK_TRAVELCARD'
]

export const fixedFares = [
  'ADVANCE',
  'ADVANCE_DAY',
  'ADVANCE_RETURN'
]

// ISO8601 Duration parser
const iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/
export const parseISO8601Duration = (iso8601Duration = '') => {
  if (iso8601Duration.length === 0) {
    return
  }
  const matches = iso8601Duration.match(iso8601DurationRegex)

  return {
    sign: matches[1] === undefined ? '+' : '-',
    years: matches[2] === undefined ? 0 : matches[2],
    months: matches[3] === undefined ? 0 : matches[3],
    weeks: matches[4] === undefined ? 0 : matches[4],
    days: matches[5] === undefined ? 0 : matches[5],
    hours: matches[6] === undefined ? 0 : matches[6],
    minutes: matches[7] === undefined ? 0 : matches[7],
    seconds: matches[8] === undefined ? 0 : matches[8]
  }
}

export const addZeroes = (num) => {
  // Convert input string to a number and store as a variable.
  var value = Number(num)
  // Split the input string into two arrays containing integers/decimals
  var res = num?.split('.')
  // If there is no decimal point or only one decimal place found.
  if (res.length === 1 || res[1].length < 3) {
    // Set the number to two decimal places
    value = value.toFixed(2)
  }
  // Return updated or original number.
  return String(value)
}
