import React from "react"

export default function Overtaken(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
            >
            <defs>
                <style>{".prefix__b{fill:#fff}"}</style>
            </defs>
            <g transform="translate(-34.619)">
                <circle
                cx={24}
                cy={24}
                r={24}
                transform="translate(34.619)"
                fill="#ce0e2d"
                />
                <path
                className="prefix__b"
                d="M58.485 24.129v5.13h-6.451v3.181h6.451v5.132l6.72-6.72z"
                />
                <path
                className="prefix__b"
                d="M65.9 15.453l-11.861.011-9.419 9.419 2.24 2.24 8.5-8.5 10.539.011v5.13l6.72-6.72-6.72-6.72z"
                />
            </g>
        </svg>
    )
}
