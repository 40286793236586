import React from 'react'
// import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

// Styles
import styles from './Modal.module.scss'

// Selectors
import { selectAppModalVisible } from '../../selectors/app'

const propTypes = {
  children: PropTypes.node.isRequired
}

export function Modal ({
  children
}) {
  const isModalVisible = useSelector(selectAppModalVisible)

  return (
    // <React.StrictMode>
    <div className={classNames(styles.modal, { [styles.visible]: isModalVisible })}>
      {isModalVisible && children}
    </div>
    // </React.StrictMode>,
    // document.getElementById('modal-root')
  )
}

Modal.propTypes = propTypes

export default Modal
