import React, { useMemo, useState } from 'react'
import { format, utcToZonedTime } from 'date-fns-tz'
import toast from 'react-hot-toast'

// Styles
import styles from './JourneyBar.module.scss'
import classNames from 'classnames'

// Components
import ArrowForward from '../../assets/ArrowForward'
import { useSelector } from 'react-redux'
import WithRailcard from '../../assets/WithRailcard'
import WithoutRailcard from '../../assets/WithoutRailcard'
import Overtaken from '../../assets/Overtaken'

// Selectors
import { selectIsCurrentlyPicking, selectOutboundMoment, selectRailcard } from '../../selectors/journeys'
import { differenceInCalendarDays, differenceInDays } from 'date-fns'


const noEticketsAvailable = () => {
  toast('This fare does not have eTickets available, this eTVM only sells eTickets', {
    duration: 3000,
  })
}

const priceHasRailcardDiscount = (price) => price.ticketableFares.ticketableFare.some(ticketableFare => ticketableFare.fareQualifiers)

function JourneyPrice ({
  price,
  hasRailcardSet,
  onPriceSelected,
  onFareInformationClicked,
  selectedOpenReturnID
}) {

  const isOpenReturn = typeof price.legReferences.legSolutionIDRef === 'object'

  let openReturnID = null
  if (isOpenReturn) {
    openReturnID = price.legReferences.legSolutionIDRef[0]
  }

  const hasEticket = useMemo(() => price.ticketableFares.ticketableFare.some(ticketableFare => ticketableFare.ticketingOptionsAvailable.attributes.XVD === 'true'), [price])
  const hasRailcardDiscount = useMemo(() => priceHasRailcardDiscount(price), [price])
  
  // User is selecting the return of their open return
  // so let's hide the price 
  const shouldShowPrice = selectedOpenReturnID === null

  return (
    <li
      key={price.attributes.priceID}
      className={classNames(styles.journeyBar, { [styles.disabled]: !hasEticket })}
    >
      <div className={styles.stationNameSmall}>
        {hasRailcardSet && (
          <div className={styles.railcardApplied}>{hasRailcardDiscount ? <WithRailcard /> : <WithoutRailcard />}</div>
        )}
        {price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].fareDisplayName}
      </div>
      <div className={styles.priceContainer} onClick={() => hasEticket ? onPriceSelected(price.attributes.priceID, openReturnID) : noEticketsAvailable()}>
        <div className={styles.priceBox}>
          <div className={styles.price}>
            {shouldShowPrice ?
              <>£{price.totalPrice.$value.split('.')[0]}
              <div className={styles.penny}>.{price.totalPrice.$value.split('.')[1]}</div>
              </>
              : 'Select'
            }
          </div>
        </div>

      </div>
      <div className={styles.infoIcon} onClick={() => onFareInformationClicked(price)} />
    </li>
  )
}

export default function JourneyBar ({
  legSolutionID,
  departureTime,
  arrivalTime,
  direct,
  overtaken,
  travelSegmentCount,
  duration,
  prices,
  onPriceSelected,
  onFareInformationClicked,
  onRouteInformationClicked
}) {
  const [expanded, setExpanded] = useState(false)

  const hasRailcardSet = useSelector(selectRailcard)
  const hasRailcardDiscount = prices.some(priceHasRailcardDiscount)

  const { dateTime: outboundMoment} = useSelector(selectOutboundMoment)

  const handleOnPriceSelected = (priceID, openReturnID = null) => {
    onPriceSelected(legSolutionID, priceID, openReturnID)
  }

  const handleOnFareInformationClicked = (price) => {
    const rules = price.ticketableFares.ticketableFare
      .map(ticketableFare =>
        ticketableFare.rules.rule.filter(rule => rule.description)
      ).flat()
    onFareInformationClicked(rules)
  }

  // If the user is picking an open return,
  // get which one so we can change the price to `Select`
  const { openReturnID: selectedOpenReturnID } = useSelector(selectIsCurrentlyPicking)

  return (
    <>
      <li className={styles.journeyBar}>
        <div className={styles.stationName}>
          {format(utcToZonedTime(departureTime, 'Europe/London'), 'HH:mm', { timeZone: 'Europe/London' })}<ArrowForward arrowFill='#382F2D' className={styles.arrow} />{format(utcToZonedTime(arrivalTime, 'Europe/London'), 'HH:mm', { timeZone: 'Europe/London' })}{' '}
          {hasRailcardSet && (
            <div className={styles.railcardApplied}>{hasRailcardDiscount ? <WithRailcard /> : <WithoutRailcard />}</div>
          )}
          <div className={styles.duration} onClick={onRouteInformationClicked}>
            {`${duration}, ${direct ? 'Direct' : `${travelSegmentCount} changes`}`}<br />
          </div>
          {overtaken && [
              <span key={`${legSolutionID}-overtaken-text`} className={styles.overtakenText}>This service is overtaken,<br /> faster journeys may be available</span>,
              <Overtaken key={`${legSolutionID}-overtaken-icon`} className={styles.overtaken}/>
          ]}
        </div>

        <div className={classNames(styles.priceContainer, styles.mainPriceContainer)}>
          {prices?.slice(0, 2).map((price) => {
            const hasEticket = price.ticketableFares.ticketableFare.some(ticketableFare => ticketableFare.ticketingOptionsAvailable.attributes.XVD === 'true')

            const isOpenReturn = typeof price.legReferences.legSolutionIDRef === 'object'

            let openReturnID = null
            if (isOpenReturn) {
              openReturnID = price.legReferences.legSolutionIDRef[0]
            }

            // User is selecting the return of their open return
            // so let's hide the price 
            const shouldShowPrice = selectedOpenReturnID === null

            return (
              <div
                key={price.attributes.priceID}
                className={classNames(styles.priceWrapper, { [styles.disabled]: !hasEticket })}
              >
                <small className={styles.fareDisplayName}>{price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].fareDisplayName}</small>
                <div className={styles.priceBox} onClick={() => hasEticket ? handleOnPriceSelected(price.attributes.priceID, openReturnID) : noEticketsAvailable()}>
                  <div className={styles.price}>
                  {shouldShowPrice ?
                    <>£{price.totalPrice.$value.split('.')[0]}
                    <div className={styles.penny}>.{price.totalPrice.$value.split('.')[1]}</div>
                    </>
                    : 'Select'
                  }
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className={styles.moreFaresToggleCol} onClick={() => setExpanded(!expanded)}>
          <small>Fare Details {prices.length > 2 && <><br />& More Fares</>}</small>
          <div className={classNames(styles.moreFaresToggle, expanded ? styles.open : null)} />
        </div>
      </li>
      {expanded && 
        <ul className={styles.journeyPricesBar}>
        {prices?.map((price) => 
          <JourneyPrice
            key={price.attributes.priceID}
            price={price}
            hasRailcardSet={hasRailcardSet}
            onPriceSelected={handleOnPriceSelected}
            onFareInformationClicked={handleOnFareInformationClicked}
            selectedOpenReturnID={selectedOpenReturnID}
          />
        )}
        </ul>
      }
    </>
  )
}
