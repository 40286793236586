import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import classNames from 'classnames'

// Actions
import { appOpenBookingOverview, appOpenHome } from '../../../actions/app'
import { OUTWARD } from '../../../actions/constants'
import { setCurrentlyPicking, setQuickReturnJourneyPicked, setSelectedItem } from '../../../actions/journeys'
import { selectCheaperFaresFound, selectIsReturnJourney, selectPickedLegSolutions, selectSelectedItems } from '../../../selectors/journeys'

// Components
import NavigationBar from '../../NavigationBar/NavigationBar'
import { RoundButton } from '../../theme'

// Styles
import styles from './CheaperFareFound.module.scss'
import { selectStationByCode } from '../../../selectors/stations'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { createBooking } from '../../../actions/bookings'


function CheaperFareFound() {
    const dispatch = useDispatch()
    const history = useHistory()
    
    const isReturn = useSelector(selectIsReturnJourney)

    // Navigation
    const handleGoHome = () => {
        dispatch(appOpenHome())
    }
    const handleGoBack = () => {
        dispatch(setCurrentlyPicking(OUTWARD, null))
        dispatch(setQuickReturnJourneyPicked(null))
        history.goBack()
    }

    // Get leg solution which will never change
    const selectedLegSolutions = useSelector(selectPickedLegSolutions)

    // Cheaper Fare Deconstruction
    const cheaperFares = useSelector(selectCheaperFaresFound)
    const { type } = cheaperFares

    // Cheaper Fare Selection Handler
    const handleOnCheaperFareSelected = (type, priceIDs) => {
        if (isReturn){
            // with open fares, the price ID will be the same
            if(type === 'open') {
                dispatch(setSelectedItem('outward', selectedLegSolutions[0].attributes.legSolutionID, priceIDs[0]))
                dispatch(setSelectedItem('return', selectedLegSolutions[1].attributes.legSolutionID, priceIDs[0]))
            } else {
                dispatch(setSelectedItem('outward', selectedLegSolutions[0].attributes.legSolutionID, priceIDs[0]))
                dispatch(setSelectedItem('return', selectedLegSolutions[1].attributes.legSolutionID, priceIDs[1]))
            }
        } else {
            dispatch(setSelectedItem('outward', selectedLegSolutions[0].attributes.legSolutionID, priceIDs[0]))
        }

        dispatch(createBooking())
        dispatch(appOpenBookingOverview())
    }

    // Continue with selected fare handler
    const handleOnSelectedFaresSelected = () => {
        dispatch(createBooking())
        dispatch(appOpenBookingOverview())
    }
    
    // Initialise content vars
    let cheaperOption

    switch (type) {
        case 'open': {
            const { cheaperFlexibleOpenPrice: cheaperFare } = cheaperFares
            const { fareDisplayName } = cheaperFare.ticketableFares.ticketableFare[0]
                .passengerReferences.passengerReference[0].fareCodes.fareCode[0]
            const price = parseFloat(cheaperFare.totalPrice.$value).toFixed(2)
            cheaperOption = (
                <>
                    <p>You can buy a valid <b>{fareDisplayName}</b> on your selected outbound service for <b>£{price}</b></p>
                    <button className={classNames(styles.button, styles.cheaper)} onClick={() => handleOnCheaperFareSelected('open', [cheaperFare.attributes.priceID])}>
                        <b>Select cheaper fare</b> (£{price})
                    </button>
                </>
            )
            break;
        }
        case 'singles': {
            const { cheapestSinglePerSolutionId: cheaperFare } = cheaperFares

            if (cheaperFare.length === 2) {
                const { fareDisplayName: firstFareDisplayName } = cheaperFare[0].ticketableFares.ticketableFare[0]
                .passengerReferences.passengerReference[0].fareCodes.fareCode[0]
                const firstPrice = cheaperFare[0].totalPrice.$value
                
                const { fareDisplayName: secondFareDisplayName } = cheaperFare[1].ticketableFares.ticketableFare[0]
                .passengerReferences.passengerReference[0].fareCodes.fareCode[0]
                const secondPrice = cheaperFare[1].totalPrice.$value

                const totalPrice = parseFloat(Number(firstPrice) + Number(secondPrice)).toFixed(2)

                cheaperOption = (
                    <>
                        <p>You can buy a valid <b>{firstFareDisplayName} (£{firstPrice})</b> and a <b>{secondFareDisplayName} (£{secondPrice})</b> on your selected services for <b>£{totalPrice}</b></p>
                        <button className={classNames(styles.button, styles.cheaper)} onClick={() => handleOnCheaperFareSelected('singles',[cheaperFare[0].attributes.priceID, cheaperFare[1].attributes.priceID])}>
                            <b>Select cheaper fare</b> (£{totalPrice})
                        </button>
                    </>
                )
            } else {
                const { fareDisplayName: firstFareDisplayName } = cheaperFare[0].ticketableFares.ticketableFare[0]
                .passengerReferences.passengerReference[0].fareCodes.fareCode[0]
                const firstPrice = cheaperFare[0].totalPrice.$value
                
                const totalPrice = parseFloat(Number(firstPrice)).toFixed(2)

                cheaperOption = (
                    <>
                        <p>You can buy a valid <b>{firstFareDisplayName} (£{firstPrice})</b> on your selected services for <b>£{totalPrice}</b></p>
                        <button className={classNames(styles.button, styles.cheaper)} onClick={() => handleOnCheaperFareSelected('singles',[cheaperFare[0].attributes.priceID])}>
                            <b>Select cheaper fare</b> (£{totalPrice})
                        </button>
                    </>
                )
            }
            break;
        }
        default:
            break;
    }

    // Selected Fare
    const selectedItems = useSelector(selectSelectedItems)
    
    const selectedTotalPrice = isReturn ?
        parseFloat([selectedItems.outward, selectedItems.return].reduce((acc, { price } ) => price ? Number(price.totalPrice.$value) + acc : acc, 0)).toFixed(2)
        : parseFloat(selectedItems.outward.price.totalPrice.$value).toFixed(2)

    return (
        <>
            <div className={styles.wrapper}>
                <h1 className={styles.title}>We've found a cheaper fare for the services you've selected</h1>
                <div className={styles.cheaperOption}>
                    {cheaperOption}
                </div>
                <div className={styles.selectedFare}>
                    <SelectedFareItem type="Outward" {...selectedItems.outward} />
                    {isReturn && <SelectedFareItem type="Return" {...selectedItems.return} />}
                    <button className={styles.button} onClick={handleOnSelectedFaresSelected}>
                        <b>Continue with selected fare</b> (£{selectedTotalPrice})
                    </button>
                </div>
            </div>
            <NavigationBar>
                <RoundButton type='home' size='large' onClick={handleGoHome} />
                <RoundButton type='back' size='large' onClick={handleGoBack} />        
            </NavigationBar>
        </>
    )
}

const SelectedFareItem = ({type, legSolution, price}) => {

    const originTravelSegment = legSolution.travelSegments.travelSegment[0]
    const destinationTravelSegment = legSolution.travelSegments.travelSegment[legSolution.travelSegments.travelSegment.length - 1]

    const fareDisplayName = price ? price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].fareDisplayName : null

    const originStation = useSelector(selectStationByCode(originTravelSegment.originTravelPoint.$value))
    const destinationStation = useSelector(selectStationByCode(destinationTravelSegment.destinationTravelPoint.$value))

    return (
        <div className={styles.fare}>
            <small>{type}</small><br/>
            <b>{format(utcToZonedTime(originTravelSegment.departureDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})} {originStation.label} - 
                {format(utcToZonedTime(destinationTravelSegment.departureDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})} {destinationStation.label}
            </b><br/>
            <span>{fareDisplayName} {price ? `, £${price.totalPrice.$value}` : ''} </span>
        </div>
    )
}

export default CheaperFareFound