// This file is a reducer, a simple method that defines
// a initial state for the store and then a switch that
// based on what action is dispatched it will return a brand new state
import * as constants from '../actions/constants'

const initialState = {
  ready: false,
  api: {
    credentials: {
      clientId: process.env.REACT_APP_RAILPAY_API_CLIENT_ID,
      clientSecret: process.env.REACT_APP_RAILPAY_API_CLIENT_SECRET
    },
    url: process.env.REACT_APP_RAILPAY_API_URL
  },
  modal: {
    visible: false
  },
  passengerModal: {
    visible: false
  },
  routeModal: {
    visible: false
  }
}

const app = (state = initialState, action) => {
  switch (action.type) {
    case constants.INIT:
      return {
        ...state,
        ready: action.payload
      }

    case constants.SET_TOKEN:
      return {
        ...state,
        api: {
          ...state.api,
          token: action.token
        }
      }

    case constants.SET_MODAL_VISIBLE:
      return {
        ...state,
        modal: {
          ...state.modal,
          // Sets argument passed or toggles
          visible: action.visible !== undefined ? action.visible : !state.modal.visible
        }
      }

    case constants.SET_PASSENGER_MODAL_VISIBLE:
      return {
        ...state,
        passengerModal: {
          ...state.passengerModal,
          // Sets argument passed or toggles
          visible: action.visible !== undefined ? action.visible : !state.passengerModal.visible
        }
      }

    case constants.SET_ROUTE_INFO_MODAL_VISIBLE:
      return {
        ...state,
        routeModal: {
          ...state.routeModal,
          // Sets argument passed or toggles
          visible: action.visible !== undefined ? action.visible : !state.routeModal.visible
        }
      }

    default:
      return state
  }
}

export default app
