import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import App from './components/App/App'
import * as serviceWorker from './serviceWorker'
import configureStore, { history } from './store'
import './styles/index.scss'

// Screens
import JourneyList from './components/Screens/JourneyList/JourneyList'
import StationPicker from './components/Screens/StationPicker/StationPicker'
import PreJourney from './components/Screens/PreJourney/PreJourney'
import JourneyResults from './components/Screens/JourneyResults/JourneyResults'
import Ticket from './components/Screens/Ticket/Ticket'
import CheaperFareFound from './components/Screens/CheaperFareFound/CheaperFareFound'

const store = configureStore()
const currentPath = store.getState().router.location.pathname

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App
        path={currentPath}
      >
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path='/home' render={() => <JourneyList />} />
            <Route exact path='/station-picker' render={() => <StationPicker />} />
            <Route exact path='/pre-search' render={() => <PreJourney />} />
            <Route exact path='/journey-results' render={() => <JourneyResults />} />
            <Route exact path='/cheaper-fare-picker' render={() => <CheaperFareFound />} />
            <Route exact path='/booking-overview' render={() => <Ticket />} />
          </Switch>
        </ConnectedRouter>
      </App>
    </Provider>
  </React.StrictMode>,
  document.getElementById('app-root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
