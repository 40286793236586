import React, { useState, useCallback, useEffect} from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { parseISO8601Duration } from '../../../utils'
import classNames from 'classnames'
import { format, utcToZonedTime } from 'date-fns-tz'
import { push } from 'connected-react-router'
import collect from 'collect.js'
import toast, { Toaster, useToasterStore } from 'react-hot-toast'

// Styles
import styles from './JourneyResults.module.scss'

// Components
import JourneyBar from '../../JourneyBar/JourneyBar'
import ArrowForward from '../../../assets/ArrowForward'
import { RoundButton, SecondaryHeader } from '../../theme'
import NavigationBar from '../../NavigationBar/NavigationBar'
import BounceLoader from 'react-spinners/BounceLoader'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Modal from '../../Modal/Modal'
import { ReactComponent as PassengerSVG } from './../../../assets/passenger.svg'
import PassengerModal from '../../PassengerModal/PassengerModal'
import RouteInfoModal from '../../RouteInfoModal/RouteInfoModal'

// Actions
import { appCloseModal, appOpenHome, appOpenModal, passengerOpenModal, routeInfoOpenModal } from '../../../actions/app'
import { findCheaperFares, setCurrentlyPicking, setSelectedItem  } from '../../../actions/journeys'

// Selectors
import {
  selectDestinationStation, selectOriginStation,
  selectFixedLegSolutionIds, selectFlexibleLegSolutionIds,
  selectFixedPriceIds, selectFlexiblePriceIds,
  selectIsLoadingJourneyResults, selectCurrentlyPickingLegSolutions,
  selectIsCurrentlyPicking, selectIsReturnJourney, selectNoJourneysFound,
  selectInboundMoment, selectOutboundMoment, selectPassengerAdult, selectRailcard, selectPriceByPriceID,
  selectOutwardSelectedItem,
} from '../../../selectors/journeys'
import { selectAppModalVisible } from '../../../selectors/app'

import { OUTWARD, RETURN } from '../../../actions/constants'
import WithRailcard from '../../../assets/WithRailcard'
import { differenceInCalendarDays } from 'date-fns'

function JourneyResults () {
  const history = useHistory()
  const dispatch = useDispatch()

  // Screen state
  const isLoadingResults = useSelector(selectIsLoadingJourneyResults)
  const { currentlyPicking, openReturnID: selectedOpenReturnID } = useSelector(selectIsCurrentlyPicking)

  const outwardSelectedItem = useSelector(selectOutwardSelectedItem)
  const outwardPrice = useSelector(selectPriceByPriceID(outwardSelectedItem.priceID)) || null
  const [currentSelectedTab, setCurrentSelectedTab] = useState(0)

  // Journey data
  const legSolutions = useSelector(selectCurrentlyPickingLegSolutions) ?? []

  const originStation = useSelector(selectOriginStation)
  const destinationStation = useSelector(selectDestinationStation)

  let fixedJourneys = useSelector(selectFixedLegSolutionIds) ?? []
  let flexibleJourneys = useSelector(selectFlexibleLegSolutionIds) ?? []

  let fixedPrices = useSelector(selectFixedPriceIds) ?? []
  let flexiblePrices = useSelector(selectFlexiblePriceIds) ?? []

  // User is picking return, let's hide open return fares
  if (currentlyPicking === RETURN & selectedOpenReturnID === null) {
    flexiblePrices = flexiblePrices.filter(price => typeof price.legReferences.legSolutionIDRef !== 'object')
  }

  // User picked open return, let's show only compatible fares
  if (selectedOpenReturnID !== null) {
    // Simply don't show fixed fares/journeys
    fixedPrices = []
    fixedJourneys = []

    const getFareCode = (price) => price.ticketableFares.ticketableFare[0]
    .passengerReferences.passengerReference[0]
    .fareCodes.fareCode[0]

    flexiblePrices = flexiblePrices
        // Filter by leg reference
        .filter(price => [price.legReferences.legSolutionIDRef].flat().includes(selectedOpenReturnID))
        // Filter by fare type & class
        .filter(price => getFareCode(price).serviceClass === getFareCode(outwardPrice).serviceClass && getFareCode(price).fareClass === getFareCode(outwardPrice).fareClass)
  }

  const isReturn = useSelector(selectIsReturnJourney)

  const hasFoundNoResults = useSelector(selectNoJourneysFound)

  const inboundMoment = useSelector(selectInboundMoment)
  const outboundMoment = useSelector(selectOutboundMoment)

  // Adult/Child/Railcard
  function openModal () {
    dispatch(passengerOpenModal())
  }

  const adultState = useSelector(selectPassengerAdult)
  const selectedRailcard = useSelector(selectRailcard)

  const handlePriceSelected = (legSolutionID, priceID, openReturnID = null) => {
    if (currentlyPicking === OUTWARD && isReturn) {
      dispatch(setSelectedItem('outward', legSolutionID, priceID))
      dispatch(setCurrentlyPicking(RETURN, openReturnID))
    } else if (currentlyPicking === RETURN && isReturn) {
      // If the user picked the return fare of an open fare
      // we need to maybe change the price they selected before
      // to match 
      if(selectedOpenReturnID){
        const selectedPrice = flexiblePrices.find(price => price.attributes.priceID === priceID)
          dispatch(setSelectedItem('outward', selectedPrice.legReferences.legSolutionIDRef[0], selectedPrice.attributes.priceID))    
      }
      dispatch(setSelectedItem('return', legSolutionID, priceID))
      // Let's see if there are cheaper fares and create the booking if not
      dispatch(findCheaperFares)
    } else if (currentlyPicking === OUTWARD && !isReturn) {
      dispatch(setSelectedItem('outward', legSolutionID, priceID))
      // Let's see if there are cheaper fares and create the booking if not
      dispatch(findCheaperFares)
    }
    // if the code gets here, something ain't right my dude
  }

  const modalOpen = useSelector(selectAppModalVisible)

  // Ticket Restrictions Modal
  const [currFareInformation, setCurrFareInformation] = useState([])
  const handleShowFareInformationModal = useCallback((fareInformation) => {
    dispatch(appOpenModal())
    setCurrFareInformation(['Routing', 'Break_of_Journey', 'Ticketing']
      .map(category => fareInformation.filter(f => f.attributes.category === category)).flat())
  }, [dispatch, setCurrFareInformation])
  const handleCloseModal = () => dispatch(appCloseModal())

  // Route Information Modal
  const [currRouteTravelSegments, setCurrRouteTravelSegments] = useState([])
  const handleShowRouteInfo = useCallback((travelSegments) => {
    setCurrRouteTravelSegments(travelSegments)
    dispatch(routeInfoOpenModal())
  }, [dispatch, setCurrRouteTravelSegments])

  const handleGoHome = () => dispatch(appOpenHome())
  const handleGoBack = () => {
    if (modalOpen) {
      handleCloseModal()
    } else {
      if (currentlyPicking === RETURN && isReturn) {
        dispatch(setCurrentlyPicking(OUTWARD))
      } else {
        history.goBack()
      }
    }
  }

  const handleEditSearch = () => {
    dispatch(push('pre-search'))
  }

  const renderLegSolutions = (legSolutions, prices, type) => {
    // No actual journeys found
    if (hasFoundNoResults) {
      return (
        <div className={styles.noFaresFound}>
          We have found no journey opportunities for your selected criteria please edit your search and try again
          <button className={classNames(styles.button, styles.search)} onClick={handleEditSearch}>
            Edit search
          </button>
        </div>
      )
    }

    // No legsolutions
    if (!legSolutions.length) {
      return (
        <div className={styles.noFaresFound}>
          {type === 'fixed'
            ? <>There are no Advance fares available for this service<br /><br />Please select a flexible fare or edit your search</>
            : <>There are no Anytime fares available for this service<br /><br />Please select a fixed fare or edit your search</>}
          <button className={classNames(styles.button, styles.search)} onClick={handleEditSearch}>
            Edit search
          </button>
        </div>
      )
    }

    return (
      <ul className={styles.journeyBarContainer}>
        {legSolutions.map((legSolution) => {
          const { hours, minutes } = parseISO8601Duration(legSolution.duration)
          const duration = `${hours}h ${minutes}m`
          const departureTime = new Date(legSolution.travelSegments.travelSegment[0].departureDateTime)
          const arrivalTime = new Date(legSolution.travelSegments.travelSegment[legSolution.travelSegments.travelSegment.length - 1].arrivalDateTime)

          const solutionPrices = collect(prices)
            .filter(
              // Only render solution prices for current legSolution
              (price) => [price.legReferences.legSolutionIDRef].flat().some(legSolutionIDRef => legSolutionIDRef === legSolution.attributes.legSolutionID)
            )
            .unique((price) => {
                const fareCode = price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0]
                return `${fareCode.attributes.code.split('-')[1]}-${fareCode.attributes.code.split('-')[2]}-${fareCode.attributes.code.split('-')[3]}`
            })
            .sort((a, b) => a.totalPrice.$value - b.totalPrice.$value)
            .toArray()

          if (solutionPrices.length === 0) {
            return null
          }

          const handleOnRouteInformationClicked = () => handleShowRouteInfo(legSolution.travelSegments.travelSegment)

          // Is the leg solution the day after the departureDate queried?
          const isLegSolutionNextDay = differenceInCalendarDays(outboundMoment.dateTime, departureTime) === -1
          
          return (
            <>
              {isLegSolutionNextDay && (
                <div className={styles.nextDayLegSolution} key={`next-day${legSolution.attributes.legSolutionID}`}>
                  <b>Next day,</b>{format(utcToZonedTime(departureTime, 'Europe/London'), 'iiii dd MMMM', { timeZone: 'Europe/London' })}
                </div>
              )}
              <JourneyBar
                key={legSolution.attributes.legSolutionID}
                legSolutionID={legSolution.attributes.legSolutionID}
                departureTime={departureTime}
                arrivalTime={arrivalTime}
                duration={duration}
                overtaken={legSolution.overtakenJourney}
                direct={legSolution.travelSegments.travelSegment.length === 1}
                travelSegmentCount={legSolution.travelSegments.travelSegment.length - 1}
                prices={solutionPrices}
                onPriceSelected={handlePriceSelected}
                onFareInformationClicked={handleShowFareInformationModal}
                onRouteInformationClicked={handleOnRouteInformationClicked}
              />
            </>
          )
        })}
      </ul>
    )
  }

  
  const renderTitle = () => {
    // eslint-disable-next-line default-case
    switch (currentlyPicking) {
      case RETURN:
        return (
          <>
            <header className={styles.secondaryHeader}>
              <span className={styles.stationName}>{destinationStation.label}</span>
              <ArrowForward className={styles.arrow} />
              <span className={styles.stationName}>{originStation.label}</span><br />
              <small
                className={styles.momentBeingChoosen}
              >
                {format(inboundMoment.dateTime, 'iiii dd MMMM HH:mm')}
                <span className={styles.change} onClick={handleEditSearch}>Change</span>
              </small>
            </header>

          </>
        )
      case OUTWARD:
        return (
          <>
            <header className={styles.secondaryHeader}>
              <span className={styles.stationName}>{originStation.label}</span>
              <ArrowForward className={styles.arrow} />
              <span className={styles.stationName}>{destinationStation.label}</span><br />
              <small
                className={styles.momentBeingChoosen}
              >
                {format(outboundMoment.dateTime, 'iiii dd MMMM HH:mm')}
                <span className={styles.change} onClick={handleEditSearch}>Change</span>
              </small>
            </header>

          </>
        )
    }
  }

  const { toasts } = useToasterStore();
  // Enforce Limit of toasts
  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= 1) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  return (
    <>
      <SecondaryHeader>{renderTitle()}</SecondaryHeader>
      <Tabs defaultIndex={currentSelectedTab} onSelect={index => setCurrentSelectedTab(index)} className={styles.tabsContainer}>
        <TabList className={styles.tabList}>
          <Tab className={styles.tab} selectedClassName={styles.selected}>Fixed</Tab>
          <Tab className={styles.tab} selectedClassName={styles.selected}>Flexible</Tab>
        </TabList>
        <div className={styles.tabsRibbon} />
        <TabPanel>
          {isLoadingResults
            ? <div className={styles.loader}><BounceLoader color='#C8102E' size='120px' loading={isLoadingResults} /></div>
            : renderLegSolutions(
              legSolutions.filter(legSolution => fixedJourneys.includes(legSolution.attributes.legSolutionID)),
              fixedPrices.filter(price => fixedJourneys.includes(price.legReferences.legSolutionIDRef)),
              'fixed'
            )}
        </TabPanel>
        <TabPanel>
          {isLoadingResults
            ? <div className={styles.loader}><BounceLoader color='#C8102E' size='120px' loading={isLoadingResults} /></div>
            : renderLegSolutions(
              legSolutions.filter(legSolution => flexibleJourneys.includes(legSolution.attributes.legSolutionID)),
              flexiblePrices.filter(price => [price.legReferences.legSolutionIDRef].flat().some(legSolutionIDRef => flexibleJourneys.includes(legSolutionIDRef))),
              'flexible'
            )}
        </TabPanel>
      </Tabs>
      <Modal>
        <div className={styles.restrictionsModal}>
          <h1 className={styles.ticketRestrictionTitle}>Ticket Restrictions</h1>
          <div className={styles.restrictions}>
            {currFareInformation.map((info, i) => <p key={`restriction-${i}`} className={styles.restriction}>{info.description}</p>)}
          </div>
          <button className={styles.button} onClick={handleCloseModal}>
            Close
          </button>
        </div>
      </Modal>
      {currentlyPicking !== RETURN &&
        (
          <div className={styles.passengerOptions}>
            <PassengerSVG className={styles.passengerIcon} />
            <div className={styles.passengerText}>
              {adultState ? 'Adult' : 'Child'} Fare, {selectedRailcard === null ? 'no railcard' :  [<WithRailcard style={{margin: '0 10'}}/> , selectedRailcard]}{' '}
            </div>
            <button onClick={openModal}><u>Change</u></button>
          </div>
        )}
      <PassengerModal />
      <RouteInfoModal travelSegments={currRouteTravelSegments} />
      <NavigationBar>
        <RoundButton type='home' size='large' onClick={handleGoHome} />
        <RoundButton type='back' size='large' onClick={handleGoBack} />
        <Toaster 
          position="bottom-center"
          toastOptions={{
            // Define default options
            style: {
              margin: '0 0 300px 0',
              background: '#363636',
              color: '#fff',
              zIndex: 1,
              fontSize: 34,
              maxWidth: '900px'
            },
          }}
        />
      </NavigationBar>
    </>
  )
}

export default JourneyResults
