import React from 'react'

// Styles
import styles from './Footer.module.scss'
import { ReactComponent as ApplePay } from '../../assets/apple-pay-white.svg'
import { ReactComponent as LNERLogo } from '../../assets/lner-logo.svg'

export default function Footer () {

  return (
    <div className={styles.footer}>
      <div className={styles.footerDescription}>Pay here using</div>
      <div className={styles.footerIcon}><ApplePay/></div>
      <div className={styles.footerIcon}><img src="google-pay.png" /></div>
      <div className={styles.LNERIcon}><LNERLogo/></div>
    </div>
  )
}
