import { createSelector } from 'reselect'

export const selectIsAppReady = (state) => state.app.ready

export const selectAPI = (state) => state.app.api

export const selectAPIUrl = createSelector(selectAPI, (api) => api.url)

export const selectAPICredentials = createSelector(selectAPI, (api) => api.credentials)

export const selectAPIToken = createSelector(selectAPI, (api) => `Bearer ${api.token.access_token}`)

export const selectCurrentPath = (state) => state.router.location.pathname || ''

export const selectAppModalVisible = (state) => state.app.modal.visible

export const selectPassengerModalVisible = (state) => state.app.passengerModal.visible

export const selectRouteInfoModalVisible = (state) => state.app.routeModal.visible

// Router
export const selectTypeOfStationPickerSelection = (state) => state.router.location.state
