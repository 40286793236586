import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Styles
import styles from './PassengerPicker.module.scss'

// Components
import { RoundButton } from '../theme'
import NavigationBar from '../NavigationBar/NavigationBar'
import RailcardSelector from '../RailcardSelector/RailcardSelector'

// Actions
import { appOpenHome, passengerCloseModal } from '../../actions/app'
import { getJourneyResults, setPassengerAdult, setRailcard, setRailcardPhase } from '../../actions/journeys'

// Selectors
import { selectPassengerAdult, selectRailcard, selectRailcardPhase } from '../../selectors/journeys'

// SVGs
import { ReactComponent as AdultFilled } from '../../assets/adult-filled.svg'
import { ReactComponent as AdultUnfilled } from '../../assets/adult-unfilled.svg'
import { ReactComponent as ChildFilled } from '../../assets/child-filled.svg'
import { ReactComponent as ChildUnfilled } from '../../assets/child-unfilled.svg'
import { ReactComponent as AddRailCard } from '../../assets/add-railcard.svg'

export default function PassengerPicker () {
  const dispatch = useDispatch()
  const toggleRailcardPhase = () => dispatch(setRailcardPhase(!railcardPhase))

  const handleCloseModal = () => {
    dispatch(getJourneyResults())
    setTimeout(() => {
      dispatch(setRailcardPhase(false))
    }, 1000)
    dispatch(passengerCloseModal())
  }

  const handleGoHome = () => {
    // Close the modal in case it's open
    handleCloseModal()
    dispatch(appOpenHome())
  }

  const setChild = () => {
    dispatch(setRailcard(null))
    dispatch(setPassengerAdult(false))
  }

  const setAdult = () => {
    dispatch(setRailcard(null))
    dispatch(setPassengerAdult(true))
  }

  const openRailcard = () => {
    dispatch(setRailcardPhase(true))
  }

  const adultPassenger = useSelector(selectPassengerAdult)
  const selectedRailcard = useSelector(selectRailcard)
  const railcardPhase = useSelector(selectRailcardPhase)

  return (
    <div className={styles.picker}>
      {railcardPhase && <RailcardSelector />}
      <div className={styles.title}> Passenger</div>
      <div className={styles.iconContainer}>
        <div className={styles.adultContainer}>
          {(adultPassenger ? <AdultFilled /> : <AdultUnfilled onClick={setAdult} />)}
        </div>
        <div className={styles.childContainer}>
          {(adultPassenger ? <ChildUnfilled onClick={setChild} /> : <ChildFilled />)}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button
          className={styles.passengerButton}
          disabled={adultPassenger}
          onClick={setAdult}
        >
          Adult
        </button>
        <button
          className={styles.passengerButton}
          disabled={!adultPassenger}
          onClick={setChild}
        >
          Child
        </button>
      </div>

      {adultPassenger &&
        <>
          <div className={styles.railcardContainer}>
            {selectedRailcard === null ? 'Add Railcard' : selectedRailcard}
          </div>
          <div className={styles.railcardIcon}>
            <AddRailCard onClick={openRailcard} />
          </div>
        </>}

      <NavigationBar>
        <RoundButton
          type='home'
          size='large'
          onClick={handleGoHome}
        />
        <RoundButton
          type='back'
          size='large'
          onClick={railcardPhase ? toggleRailcardPhase : handleCloseModal}
        />

        <RoundButton
          type='confirm'
          size='large'
          align='right'
          onClick={handleCloseModal}
        />
        <RoundButton
          type='cancel'
          size='large'
          onClick={handleCloseModal}
        />
      </NavigationBar>
    </div>
  )
}
