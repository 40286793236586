import axios from 'axios'

// Selectors
import { selectAPIUrl, selectAPIToken } from '../selectors/app'
import { selectJourneyPassengers,  selectPickedLegSolutions, selectPickedPrices } from '../selectors/journeys'
import { SET_BOOKING, SET_ERROR_CREATING_BOOKING, SET_IS_CREATING_BOOKING } from './constants'

// Actions

export const setIsCreatingBooking = (isCreating) => (dispatch) => dispatch({
  type: SET_IS_CREATING_BOOKING,
  isCreating
})

export const setBooking = (booking) => (dispatch) => dispatch({
  type: SET_BOOKING,
  booking
})

export const setErrorCreatingBooking = (error) => (dispatch) => dispatch({
  type: SET_ERROR_CREATING_BOOKING,
  error
})

// Thunks
export const createBooking = () => (dispatch, getState) => {
  dispatch(setBooking(null))
  dispatch(setErrorCreatingBooking(false))
  const token = selectAPIToken(getState())
  const url = selectAPIUrl(getState())

  // Format passenger information for booking creation
  // maybe the selector could do this?
  const passengers = selectJourneyPassengers(getState()).map((passenger) => ({
    passengerID: passenger.attributes.passengerSpecID,
    ageAtTimeOfTravel: passenger.age
  }))

  const legSolutions = selectPickedLegSolutions(getState())

  const prices = selectPickedPrices(getState())

  dispatch(setIsCreatingBooking(true))
  axios.post(`${url}/v1/bookings`, {
    passengers,
    legSolutions,
    prices
  }, {
    headers: {
      Authorization: token
    }
  }).then(({ data }) => {
    dispatch(retrieveBooking(data.recordLocator))
  }).catch((data) => {
    dispatch(setErrorCreatingBooking(true))
    dispatch(setIsCreatingBooking(false))
  })
}

export const retrieveBooking = (recordLocator) => (dispatch, getState) => {
  const token = selectAPIToken(getState())
  const url = selectAPIUrl(getState())

  axios.get(`${url}/v1/bookings/${recordLocator}`, {
    headers: {
      Authorization: token
    }
  }).then(({ data }) => {
    dispatch(setBooking(data))
    dispatch(setIsCreatingBooking(false))
  })
}
