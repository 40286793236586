import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { isBefore, setHours, setMinutes, subDays } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

// Styles
import styles from './Ticket.module.scss'

// Actions
import { appCloseModal, appOpenHome, appOpenJourneyResults, appOpenModal, routeInfoOpenModal } from '../../../actions/app'

// Selectors
import {
  selectIsCreatingBooking, selectBookingHandoffQRCode,
  selectBookingTotalPrice, selectBookingTrainTravelSegmentsByTicketableFareID,
  selectBookingOrderedLegTicketableFares,
  selectBookingTravelSegmentsByTicketableFareID,
  selectErrorCreatingBooking
} from '../../../selectors/bookings'
import { selectIsCurrentlyPicking, selectPassengerAdult, selectRailcard } from '../../../selectors/journeys'
import { selectStationByCode } from '../../../selectors/stations'

// Components
import BounceLoader from 'react-spinners/BounceLoader'
import NavigationBar from '../../NavigationBar/NavigationBar'
import ArrowForward from '../../../assets/ArrowForward'
import { RoundButton } from '../../theme'
import Modal from '../../Modal/Modal'
import RouteInfoModal from '../../RouteInfoModal/RouteInfoModal'
import { setCurrentlyPicking, setQuickReturnJourneyPicked } from '../../../actions/journeys'
import { OUTWARD } from '../../../actions/constants'

const TicketItem = ({
  ticketableFare,
  index,
  onFareInformationClicked,
  onRouteInformationClicked
}) => {
  const filterByLeg = (segment) => segment.attributes.legGrouping === ticketableFare.legGrouping
  const travelSegments = useSelector(selectBookingTravelSegmentsByTicketableFareID(ticketableFare.attributes.ticketableFareID)).filter(filterByLeg)
  const trainTravelSegments = useSelector(selectBookingTrainTravelSegmentsByTicketableFareID(ticketableFare.attributes.ticketableFareID)).filter(filterByLeg)
  const fares = ticketableFare.passengerReferences.passengerReference[0].fareCodes.fareCode
  const originFare = fares[0]
  const destinationFare = fares[fares.length - 1]
  const { openReturnID: selectedOpenReturnID } = useSelector(selectIsCurrentlyPicking)

  const originTravelSegment = trainTravelSegments[0]
  const destinationTravelSegment = trainTravelSegments[trainTravelSegments.length - 1]

  const railcard = useSelector(selectRailcard)
  const passengerAdult = useSelector(selectPassengerAdult)

  const originStation = useSelector(selectStationByCode(originTravelSegment.originTravelPoint.$value))
  const destinationStation = useSelector(selectStationByCode(destinationTravelSegment.destinationTravelPoint.$value))

  const handleOnFareInformationClicked = useCallback((price) => {
    const rules = ticketableFare.rules.rule.filter(rule => rule.description).flat()
    onFareInformationClicked(rules)
  }, [onFareInformationClicked, ticketableFare])

  const handleOnRouteInformationClicked = useCallback(() => {
    onRouteInformationClicked(travelSegments)
  }, [onRouteInformationClicked, travelSegments])

  const fareToUse = index === 0 ? originFare?.fareExpirationDateTime : destinationFare?.fareExpirationDateTime
  const endOfTrainDay = setMinutes(setHours(new Date(fareToUse), 4), 30)
  const fareExpirationDateTime = isBefore(new Date(fareToUse), endOfTrainDay)
    ? format(subDays(new Date(fareToUse), 1), 'd MMM')
    : format(new Date(fareToUse), 'd MMM')

  return (
    <>
      <div className={styles.ticketHeader}>
        {index === 0 ? 'Outward' : 'Return'}
        
        <span className={styles.departDate}>{format(utcToZonedTime(originTravelSegment.departureDateTime, 'Europe/London'), 'iiii, d MMM', {timeZone: 'Europe/London'})}</span>
      </div>

      <div className={styles.ticketBody}>
        {/* <div className={styles.trainsContainer}> */}
        <div className={styles.originContainer}>
          From
          <div className={styles.propText}>{originStation.label}</div>
          <div className={styles.propText}>{format(utcToZonedTime(originTravelSegment.departureDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})}</div>
          <div className={styles.durationText} onClick={handleOnRouteInformationClicked}>{travelSegments.length === 1 ? 'direct' : `${travelSegments.length - 1} changes`}</div>
          <div className={styles.ticketType}>{originFare?.fareDisplayName}<span onClick={handleOnFareInformationClicked} className={styles.ticketRestrictions} /></div>
        </div>

        <ArrowForward className={styles.arrow} />

        <div className={styles.destinationContainer}>
          To
          <div className={styles.propText}>{destinationStation.label}</div>
          <div className={styles.propText}>{format(utcToZonedTime(destinationTravelSegment.arrivalDateTime, 'Europe/London'), 'HH:mm', {timeZone: 'Europe/London'})}</div>
        </div>

        <div className={styles.validityContainer}>
          Valid
          <div className={styles.propText}>{fareExpirationDateTime}</div>
          <div className={styles.passengerContainer}>Passenger</div>
          <div className={styles.propText}>
            {passengerAdult ? 'Adult' : 'Child'} {railcard ? `,${railcard}` : null}
          </div>
          {selectedOpenReturnID === null && <div className={styles.price}>£{ticketableFare?.totalPrice?.$value}</div>}
        </div>
        <div className={classNames(styles.ticketByte, styles.bottomLeft)} />
        <div className={classNames(styles.ticketByte, styles.bottomRight)} />
      </div>
    </>
  )
}
export default function Ticket () {
  const dispatch = useDispatch()

  // Screen state
  const isCreatingBooking = useSelector(selectIsCreatingBooking)
  const hadErrorCreatingBooking = useSelector(selectErrorCreatingBooking)
  const orderedLegTicketableFares = useSelector(selectBookingOrderedLegTicketableFares)
  const qrCode = useSelector(selectBookingHandoffQRCode)
  const totalPrice = useSelector(selectBookingTotalPrice)

  const handleGoHome = useCallback(() => dispatch(appOpenHome()), [dispatch])
  const handleGoBack = useCallback(() => {
    dispatch(setCurrentlyPicking(OUTWARD, null))
    dispatch(setQuickReturnJourneyPicked(null))
    dispatch(appOpenJourneyResults())
  }, [dispatch])

  // Ticket Restrictions Modal
  const [currFareInformation, setCurrFareInformation] = useState([])
  const handleShowFareInformationModal = useCallback((fareInformation) => {
    dispatch(appOpenModal())
    setCurrFareInformation(['Routing', 'Break_of_Journey', 'Ticketing']
      .map(category => fareInformation.filter(f => f.attributes.category === category)).flat())
  }, [dispatch, setCurrFareInformation])
  const handleCloseModal = useCallback(() => dispatch(appCloseModal()), [dispatch])

  // Route Information Modal
  const [currRouteTravelSegments, setCurrRouteTravelSegments] = useState([])
  const handleShowRouteInfo = useCallback((travelSegments) => {
    setCurrRouteTravelSegments(travelSegments)
    dispatch(routeInfoOpenModal())
  }, [dispatch, setCurrRouteTravelSegments])

  if (hadErrorCreatingBooking) {
    return (
      <>
        <div className={styles.ticketContainer}>
          <div className={styles.errorContainer}>
            
            <h1>Oops there seems to have been a problem reserving this train.</h1>
            <h2>The most common reason for this is that the train is fully reserved. If you experience this again, please seek help at the Travel Centre.</h2>
          </div>
        </div>
        <NavigationBar>
          <RoundButton type='home' size='large' onClick={handleGoHome} />
          <RoundButton type='back' size='large' onClick={handleGoBack} />
        </NavigationBar>
      </>
    )
  }

  if (isCreatingBooking) {
    return (
      <>
        <div className={styles.ticketContainer}>
          <div className={styles.loader}>
            <BounceLoader color='#C8102E' size='120px' loading={isCreatingBooking} />
          </div>
        </div>
        <NavigationBar>
          <RoundButton type='home' size='large' onClick={handleGoHome} />
          <RoundButton type='back' size='large' onClick={handleGoBack} />
        </NavigationBar>
      </>
    )
  }

  return (
    <>
      <div className={styles.ticketContainer}>
        {orderedLegTicketableFares.map((ticketableFare, i) =>
          <TicketItem
            key={`ticketableFare${i}`}
            ticketableFare={ticketableFare}
            onFareInformationClicked={handleShowFareInformationModal}
            onRouteInformationClicked={handleShowRouteInfo}
            index={i}
          />
        )}
        <div className={styles.ticketFold}>
          <div className={classNames(styles.ticketByte, styles.upperLeft)} />
          <div className={classNames(styles.ticketByte, styles.upperRight)} />
          <div className={classNames(styles.ticketByte, styles.bottomLeft)} />
          <div className={classNames(styles.ticketByte, styles.bottomRight)} />
          <div className={styles.foldText}>Total:</div>
          <div className={styles.foldTotal}>£{totalPrice}</div>
        </div>

        <div className={styles.ticketFooter}>
          <div
            className={styles.QRCode}
            dangerouslySetInnerHTML={{
              __html: qrCode
            }}
          />
          <div className={styles.footerText}>
            To complete your purchase with your phone, please scan the QR code<br /><br />
            Your seats will be reserved where required, and will be provided upon purchase
          </div>
          <div className={styles.logosContainer}>
            <div className={styles.applePay} />
            <div className={styles.googlePay} />
          </div>
        </div>
      </div>
      <Modal>
        <div className={styles.restrictionsModal}>
          <h1 className={styles.ticketRestrictionTitle}>Ticket Restrictions</h1>
          <div className={styles.restrictions}>
            {currFareInformation.map((info, i) => <p key={`restriction-${i}`} className={styles.restriction}>{info.description}</p>)}
          </div>
          <button className={styles.button} onClick={handleCloseModal}>
            Close
          </button>
        </div>
      </Modal>
      <RouteInfoModal travelSegments={currRouteTravelSegments} />
      <NavigationBar>
        <RoundButton type='home' size='large' onClick={handleGoHome} />
        <RoundButton type='back' size='large' onClick={handleGoBack} />
        <button className={styles.finishButton} onClick={handleGoHome}>Finished</button>
      </NavigationBar>
    </>
  )
}
