import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

// Components
import PassengerPicker from '../PassengerPicker/PassengerPicker'

// Styles
import styles from './PassengerModal.module.scss'

// Selectors
import { selectPassengerModalVisible } from '../../selectors/app'

export function Modal () {
  const isModalVisible = useSelector(selectPassengerModalVisible)

  return (
    <div className={classNames(styles.modal, { [styles.visible]: isModalVisible })}>
      <PassengerPicker />
    </div>
  )
}

export default Modal
