import axios from 'axios'
import qs from 'querystring'
import {
  INIT, SET_TOKEN, SET_MODAL_VISIBLE, SET_PASSENGER_MODAL_VISIBLE, SET_ROUTE_INFO_MODAL_VISIBLE
} from './constants'

import { push } from 'connected-react-router'

// Actions
import { getStations } from './stations'
import { getRailcards } from './railcards'
import { resetMoments, resetSelectedItems, setCurrentlyPicking, setDestinationStation, setIsReturnJourney, setOriginStation, setPassengerAdult, setQuickReturnJourneyPicked, setRailcard } from './journeys'

// Selectors
import { selectAPICredentials, selectAPIUrl } from '../selectors/app'
import { getTOCs } from './tocs'

// This is a Redux Action, a method that dispatched an Action type
// that the reducer will catch and do stuff to the store with
export const appInit = (ready = false) => (dispatch) => dispatch({
  type: INIT,
  payload: ready
})

// Gets the OAuth token from the server
export const appGetToken = () => (dispatch, getState) => {
  const url = selectAPIUrl(getState())
  const { clientId, clientSecret } = selectAPICredentials(getState())

  return axios.post(
    `${url}/oauth/token`,
    qs.stringify({
      grant_type: 'client_credentials',
      client_id: clientId,
      client_secret: clientSecret,
      scope: 'api-access'
    }),
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
  ).then(({ data }) => {
    dispatch({
      type: SET_TOKEN,
      token: data
    })
    // Let's renew the token in less than 4h
    setTimeout(() => dispatch(appGetToken()), 14200000)
  }).catch((error) => {
    console.error(error)
  })
}

export const appInitialize = (dispatch, getState) => {
  dispatch(appInit(true))

  // We're getting the stations right after initiating the app
  // because it's quite a big list
  dispatch(getStations())
  dispatch(getRailcards())
  dispatch(getTOCs())

  // When we're done, let's push our app to Home
  dispatch(push('home'))
}

// Screen Management

// Screens
export const appOpenStationPicker = (type) => (dispatch) => dispatch(push('station-picker', type))

export const appOpenHome = () => (dispatch) => {
  dispatch(push('home'))
  dispatch(setOriginStation('GBNCL'))
  dispatch(setDestinationStation(null))
  dispatch(setQuickReturnJourneyPicked(null))
  dispatch(setIsReturnJourney(false))
  dispatch(setRailcard(null))
  dispatch(setPassengerAdult(true))
  dispatch(appCloseModal())
  dispatch(resetMoments())
  dispatch(resetSelectedItems())
  dispatch(setCurrentlyPicking(0, null))
}

export const appOpenJourneyResults = () => (dispatch) => dispatch(push('journey-results'))

export const appOpenBookingOverview = () => (dispatch) => dispatch(push('booking-overview'))

export const appOpenCheaperFareFound = (cheaperFare) => (dispatch) => dispatch(push('cheaper-fare-picker', cheaperFare))

// Modals
export const appOpenModal = () => (dispatch) => dispatch({
  type: SET_MODAL_VISIBLE,
  visible: true
})

export const appCloseModal = () => (dispatch) => dispatch({
  type: SET_MODAL_VISIBLE,
  visible: false
})

export const appToggleModal = () => (dispatch) => dispatch({
  type: SET_MODAL_VISIBLE
})

export const passengerOpenModal = () => (dispatch) => dispatch({
  type: SET_PASSENGER_MODAL_VISIBLE,
  visible: true
})

export const passengerCloseModal = () => (dispatch) => dispatch({
  type: SET_PASSENGER_MODAL_VISIBLE,
  visible: false
})

export const passengerToggleModal = () => (dispatch) => dispatch({
  type: SET_PASSENGER_MODAL_VISIBLE
})

export const routeInfoOpenModal = () => (dispatch) => dispatch({
  type: SET_ROUTE_INFO_MODAL_VISIBLE,
  visible: true
})

export const routeInfoCloseModal = () => (dispatch) => dispatch({
  type: SET_ROUTE_INFO_MODAL_VISIBLE,
  visible: false
})

export const routeInfoToggleModal = () => (dispatch) => dispatch({
  type: SET_ROUTE_INFO_MODAL_VISIBLE
})
