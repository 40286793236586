// This file is a reducer, a simple method that defines
// a initial state for the store and then a switch that
// based on what action is dispatched it will return a brand new state
import * as constants from '../actions/constants'

const initialState = {
  booking: null,
  isCreatingBooking: false
}

const journey = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_IS_CREATING_BOOKING:
      return {
        ...state,
        isCreatingBooking: action.isCreating
      }

    case constants.SET_BOOKING:
      return {
        ...state,
        booking: {
          ...action.booking
        }
      }

    case constants.SET_ERROR_CREATING_BOOKING:
      return {
        ...state,
        errorCreatingBooking: action.error
      }

    default:
      return state
  }
}

export default journey
