import React, { useEffect, useState } from 'react'

// Styles
import styles from './Clock.module.scss'

export function Clock () {
  const [date, setDate] = useState(new Date())

  const tick = () => setDate(new Date())

  useEffect(() => {
    const timer = setInterval(() => tick(), 1000)
    return function cleanup () {
      clearInterval(timer)
    }
  })

  return (
    <div className={styles.clock}>
      {date.toLocaleTimeString([], { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false })}
    </div>
  )
}

export default React.memo(Clock)
