import React from 'react'

// Styles
import styles from './Header.module.scss'

export default function Header ({ children }) {
  return (
    <div className={styles.Header}>
      <h1 className={styles.headerText}>{children}</h1>
    </div>
  )
}
