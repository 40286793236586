import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import classNames from 'classnames'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { push } from 'connected-react-router'

// Styles
import styles from './StationPicker.module.scss'
import 'react-simple-keyboard/build/css/index.css'
import './keyboard.css'

// Components
import { RoundButton, SecondaryHeader } from '../../theme'
import Keyboard from 'react-simple-keyboard'
import Downshift from 'downshift'
import NavigationBar from '../../NavigationBar/NavigationBar'

// Selectors
import { selectMainStations } from '../../../selectors/stations'
import { selectTypeOfStationPickerSelection } from '../../../selectors/app'

// Actions
import { setDestinationStation, setOriginStation } from '../../../actions/journeys'
import { appOpenHome } from '../../../actions/app'
import { selectDestinationStation, selectOriginStation } from '../../../selectors/journeys'

const keyboardLayout = {
  default: [
    'q w e r t y u i o p',
    'a s d f g h j k l',
    'z x c v b n m',
    '{space} {bksp}'
  ]
}

function StationPicker () {
  const dispatch = useDispatch()
  const history = useHistory()
  const [input, setInput] = useState('')
  const [selectedStation, setSelectedStation] = useState(null)

  // Currently picked stations
  const originStation = useSelector(selectOriginStation)
  const destinationStation = useSelector(selectDestinationStation)

  const keyboard = useRef()

  const stations = useSelector(selectMainStations)
  const typeOfSelection = useSelector(selectTypeOfStationPickerSelection)

  const onChange = input => {
    setInput(input)
    keyboard.current.setInput(input)
  }

  const onClearInput = () => {
    setInput('')
    keyboard.current.setInput('')
  }

  const onSelectDestination = (station) => {
    setSelectedStation(station)
  }

  const onContinue = (selectedStation) => {
    if (typeOfSelection === 'destination') {
      dispatch(setDestinationStation(selectedStation))
    } else {
      dispatch(setOriginStation(selectedStation))
    }
    dispatch(push('pre-search'))
  }

  const handleGoHome = () => dispatch(appOpenHome())
  const handleGoBack = () => history.goBack()

  return (
    <>
      <SecondaryHeader><header>{typeOfSelection === 'destination' ? 'Travelling to' : 'Travelling from'}</header></SecondaryHeader>
      <div className={styles.inputWrapper}>
        <Downshift
          inputValue={input}
          onChange={(selection) => {
            onChange(selection.label)
            onSelectDestination(selection.value)
            onContinue(selection.value)
          }}
          isOpen={input.length > 2}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            getLabelProps,
            getToggleButtonProps,
            inputValue,
            highlightedIndex,
            selectedItem,
            isOpen
          }) => (
            <div>
              <input {...getInputProps({
                className: styles.input,
                placeholder: 'Search...'
              })}
              />
              <ul {...getMenuProps({
                className: styles.autocompleteWrapper
              })}
              >
                {isOpen &&
                  stations
                    .filter((station) => station.value !== originStation?.value && station.value !== destinationStation?.value) 
                    .filter((station) => !inputValue || station.label.toLowerCase().includes(inputValue.toLowerCase()))
                    .sort((a, b) => b.value.indexOf('@'))
                    .map((item, index) => {
                      const matches = match(item.label, inputValue)
                      const parts = parse(item.label, matches)
                      return (
                        <li
                          {...getItemProps({
                            key: `${item.value}${index}`,
                            item,
                            index,
                            className: styles.autocompleteItem
                          })}
                        >
                          {parts.map((part, i) => {
                            const className = part.highlight ? styles.highlight : null
                            return (
                              <span className={className} key={i}>{part.text}</span>
                            )
                          })}
                        </li>
                      )
                    })}
              </ul>
            </div>
          )}
        </Downshift>
        <RoundButton
          className={classNames(styles.clearButton, {
            [styles.enabled]: input.length > 0
          })}
          disabled={input.length === 0}
          onClick={onClearInput}
        >
          𝗫
        </RoundButton>
      </div>
      <Keyboard
        layout={keyboardLayout}
        theme='hg-theme-default keyboard'
        modules
        buttonTheme={[
          {
            class: styles.normalKey,
            buttons: 'q w e r t y u i o p a s d f g h j k l z x c v b n m'
          },
          {
            class: styles.space,
            buttons: '{space}'
          },
          {
            class: styles.backspace,
            buttons: '{bksp}'
          }
        ]}
        display={{
          '{bksp}': '⌫',
          '{space}': 'SPACE'
        }}
        keyboardRef={(r) => (keyboard.current = r)}
        onChange={onChange}
      />
      <NavigationBar>
        <RoundButton
          type='home'
          size='large'
          onClick={handleGoHome}
        />
        <RoundButton
          type='back'
          size='large'
          onClick={handleGoBack}

        />
        <RoundButton
          type='confirm'
          size='large'
          align='right'
          disabled={!selectedStation}
          onClick={onContinue}
        />
      </NavigationBar>
    </>
  )
}

export default StationPicker
