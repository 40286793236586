import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Picker from 'react-mobile-picker'

// //styles
import styles from './RailcardSelector.module.scss'

// Components

// Actions
import { setRailcard } from '../../actions/journeys'

// Selectors
import { selectPassengerAdult, selectRailcard } from '../../selectors/journeys'
import { selectRailcardByPassengerType } from '../../selectors/railcards'

export default function RailcardSelector () {
  const dispatch = useDispatch()
  const isPassengerAdult = useSelector(selectPassengerAdult)

  const selectedRailcard = useSelector(selectRailcard)
  const railcards = useSelector(selectRailcardByPassengerType(isPassengerAdult ? 'adult' : 'child'))

  const [valueGroups, setvalueGroups] = useState({
    railcard: selectedRailcard
  })

  const [optionGroups, setOptionGroups] = useState({
    railcard: ['None', ...railcards.map(railcard => railcard.description).sort(function (a, b) {
      if (a < b) { return -1 }
      if (a > b) { return 1 }
      return 0
    })]
  })

  const handleChange = (name, value) => {
    if (value === 'None') {
      dispatch(setRailcard(null))
    } else {
      dispatch(setRailcard(value))
    }
    setvalueGroups(prevValue => {
      return {
        ...valueGroups,
        [name]: value
      }
    })
  }

  return (
    <div className={styles.railcardSelector}>
      <div className={styles.title}> Select Railcard </div>
      <div className={styles.railcardContainer}>

        <Picker
          itemHeight={70}
          height={1000}
          optionGroups={optionGroups}
          valueGroups={valueGroups}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
