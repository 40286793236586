import { collect } from 'collect.js'
import { createSelector } from 'reselect'
import { fixedFares, flexibleFares } from '../utils'

export const selectJourneys = (state) => state.journeys

export const selectHomeJourneys = createSelector(selectJourneys, (journeys) => journeys.homeJourneys)

export const selectOriginStation = (state) => state.stations.list.find(item => item.value === state.journeys.originStation)

export const selectDestinationStation = (state) => state.stations.list.find(item => item.value === state.journeys.destinationStation)

export const selectOutboundMoment = createSelector(selectJourneys, (journeys) => journeys.outboundMoment)

export const selectInboundMoment = createSelector(selectJourneys, (journeys) => journeys.inboundMoment)

export const selectIsReturnJourney = createSelector(selectJourneys, (journeys) => journeys.isReturnJourney)

export const selectIsLoadingJourneyResults = createSelector(selectJourneys, (journeys) => journeys.isLoadingResults)

export const selectQuickReturnJourneyPicked = createSelector(selectJourneys, (journeys) => journeys.quickReturnJourneySelected)

export const selectIsCurrentlyPicking = createSelector(selectJourneys, (journeys) => {
  const {
    currentlyPicking,
    openReturnID
  } = journeys
  return {
    currentlyPicking,
    openReturnID
  }
}

)

export const selectJourneyResults = createSelector(selectJourneys, (journeys) => journeys.results)

export const selectNoJourneysFound = createSelector(
  selectJourneys,
  (journeys) => journeys.noJourneysFound
)

export const selectOutwardSelectedItem = createSelector(
  selectJourneys,
  (journeys) => journeys.selectedItems.outward
)

export const selectReturnSelectedItem = createSelector(
  selectJourneys,
  (journeys) => journeys.selectedItems.return
)

export const selectCurrentlyPickingLegSolutions = createSelector(
  selectJourneyResults,
  selectIsCurrentlyPicking,
  (results, { currentlyPicking }) => results?.legs?.leg[currentlyPicking]?.legSolutions?.legSolution
    .sort((l1,l2) => new Date(l1.travelSegments.travelSegment[0].departureDateTime) - new Date(l2.travelSegments.travelSegment[0].departureDateTime)) ?? []
)

export const selectJourneyPassengers = createSelector(selectJourneyResults,
  (results) => results?.passengers.passenger
)

export const selectJourneyOutwardLegSolutions = createSelector(
  selectJourneyResults,
  (results) => results?.legs.leg[0].legSolutions.legSolution
)

export const selectJourneyReturnLegSolutions = createSelector(
  selectJourneyResults,
  (results) => results?.legs.leg[1]?.legSolutions.legSolution
)

export const selectJourneyPrices = createSelector(selectJourneyResults,
  (results) => results?.fareInformation?.prices?.pointToPointPrice
)

export const selectPassengerAdult = createSelector(selectJourneys, (journeys) => journeys.passengerAdult)

export const selectRailcard = createSelector(selectJourneys, (journeys) => journeys.railcard)

export const selectRailcardPhase = createSelector(selectJourneys, (journeys) => journeys.railcardPhase)

const filterOutIllegalJourneys = (price) => {
  if (price.ticketableFares.ticketableFare.length <= 1) {
    return true
  }

  const fareCode1 = price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].attributes.code
  const fareCode2 = price.ticketableFares.ticketableFare[1].passengerReferences.passengerReference[0].fareCodes.fareCode[0].attributes.code

  return fareCode1.substring(0, 12) === fareCode2.substring(0, 12)
}

const filterOutNonEticketJourneys = (price) => {
  return price.ticketableFares.ticketableFare.some(ticketableFare => ticketableFare.ticketingOptionsAvailable.attributes.XVD === 'true')
}

export const selectFixedLegSolutionIds = createSelector(selectJourneyPrices,
  (prices) => prices
    ?.filter(price => fixedFares.includes(price.restrictiveFareClass))
    ?.filter(filterOutIllegalJourneys)
    .flatMap(price => price.legReferences.legSolutionIDRef)
)

export const selectFlexibleLegSolutionIds = createSelector(selectJourneyPrices,
  (prices) => prices
    ?.filter(price => flexibleFares.includes(price.restrictiveFareClass))
    ?.filter(filterOutIllegalJourneys)
    .flatMap(price => price.legReferences.legSolutionIDRef)
)

export const selectFixedPriceIds = createSelector(selectJourneyPrices,
  (prices) => prices
    ?.filter(filterOutIllegalJourneys)
    ?.filter(price => fixedFares.includes(price.restrictiveFareClass))
)

export const selectFlexiblePriceIds = createSelector(selectJourneyPrices,
  (prices) => prices
    ?.filter(filterOutIllegalJourneys)
    ?.filter(price => flexibleFares.includes(price.restrictiveFareClass))
)

export const selectPickedLegSolutions = createSelector(
  selectIsReturnJourney,
  selectOutwardSelectedItem,
  selectReturnSelectedItem,
  selectJourneyOutwardLegSolutions,
  selectJourneyReturnLegSolutions,
  (isReturn, outwardItem, returnItem, outwardLegSolutions, returnLegSolutions) => {
    if (isReturn) {
      return [
        outwardLegSolutions.find(legSolution => legSolution.attributes.legSolutionID === outwardItem.legSolutionID),
        returnLegSolutions.find(legSolution => legSolution.attributes.legSolutionID === returnItem.legSolutionID)
      ]
    }
    return [
      outwardLegSolutions.find(legSolution => legSolution.attributes.legSolutionID === outwardItem.legSolutionID)
    ]
  }
)

export const selectPickedPrices = createSelector(
  selectIsReturnJourney,
  selectOutwardSelectedItem,
  selectReturnSelectedItem,
  selectJourneyPrices,
  (isReturn, outwardItem, returnItem, prices) => {
    if (isReturn) {
      return prices.filter(price => price.attributes.priceID === outwardItem.priceID || price.attributes.priceID === returnItem.priceID)
    }
    return [prices.find(price => price.attributes.priceID === outwardItem.priceID)]
  }
)

export const selectPriceByPriceID = (priceID) => createSelector(
  selectJourneyPrices,
  (prices = []) => prices.find(price => price.attributes.priceID === priceID)
)

export const selectCheaperFaresFound = createSelector(
  selectJourneys,
  (journeys) => journeys.cheaperFaresFound
)

export const selectCheaperFaresFromSelectedJourneys = createSelector(
  selectPickedLegSolutions,
  selectPickedPrices,
  selectJourneyPrices,
  selectIsReturnJourney,
  (pickedLegSolutions, pickedPrices, allPrices, isReturn) => {
    if (isReturn) {
      const legSolutionIDS = pickedLegSolutions.map(legSolution => legSolution.attributes.legSolutionID)
      const priceIDs = pickedPrices.map(price => price.attributes.priceID)
      const faresServiceClass = pickedPrices.map(price => price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].cabinClass)

      // Get compatible prices with Leg Solutions
      const compatiblePrices = collect(allPrices)
        .filter(price => [price.legReferences.legSolutionIDRef].flat().every(legSolutionID => legSolutionIDS.includes(legSolutionID)))
        .filter(filterOutIllegalJourneys)
        .filter(filterOutNonEticketJourneys)
        .filter(price => !priceIDs.includes(price.attributes.priceID))
      
      // Calculate current total price
      const totalCurrentPrice = pickedPrices.reduce((acc, price) => acc + Number(price.totalPrice.$value), 0)

      // Get possible cheaper open fare prices
      const cheaperFlexibleOpenPrice = compatiblePrices
        .filter(price => flexibleFares.includes(price.restrictiveFareClass))
        .filter(price => [price.legReferences.legSolutionIDRef].flat().toString() === legSolutionIDS.toString())
        .filter(price => price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].cabinClass === faresServiceClass[0] && price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].cabinClass === faresServiceClass[1])
        .filter(price => price.totalPrice.$value < totalCurrentPrice)
        .first()

        // Get possible cheaper non open prices
      const cheapestSinglePerSolutionId = legSolutionIDS.map((legSolutionID, i) => {
        const foundCheaper = compatiblePrices
          .filter(price => [price.legReferences.legSolutionIDRef].flat().toString() === legSolutionID)
          .filter(price => price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].cabinClass === faresServiceClass[i])
          .sort((a, b) => a.totalPrice.$value - b.totalPrice.$value)
          .first()
          // If we didn't find a cheaper one, put the selected one to give space for a possible cheaper return single
          return foundCheaper || pickedPrices[i]
      })
      
      


      const cheapestSinglePerSolutionIdPrice = cheapestSinglePerSolutionId?.reduce((acc, p) => acc + Number(p?.totalPrice?.$value), 0)

      // If we found a cheaperFlexibleOpenPrice, is it cheaper than 2 singles? and the totalCurrentPrice?
      if (
        cheaperFlexibleOpenPrice?.totalPrice?.$value < cheapestSinglePerSolutionIdPrice  && 
        cheaperFlexibleOpenPrice?.totalPrice?.$value < totalCurrentPrice
      ) {
        return { type: 'open', cheaperFlexibleOpenPrice}
      }

      // Is cheapestSingles cheaper than the current price?
      if (
        cheapestSinglePerSolutionIdPrice < totalCurrentPrice
      ) {
        return { type: 'singles', cheapestSinglePerSolutionId }
      }

      return null
    }

    // Single calculation
    const legSolutionIDS = pickedLegSolutions.map(legSolution => legSolution.attributes.legSolutionID)
    const priceIDs = pickedPrices.map(price => price.attributes.priceID)
    const faresServiceClass = pickedPrices.map(price => price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].cabinClass)
    
    // Get compatible prices with Leg Solution
    const compatiblePrices = collect(allPrices)
      .filter(price => [price.legReferences.legSolutionIDRef].flat().every(legSolutionID => legSolutionIDS.includes(legSolutionID)))
      .filter(filterOutIllegalJourneys)
      .filter(filterOutNonEticketJourneys)
      .filter(price => !priceIDs.includes(price.attributes.priceID))
    
    // Calculate current total price
    const totalCurrentPrice = pickedPrices.reduce((acc, price) => acc + Number(price.totalPrice.$value), 0)

    // Get possible cheaper open fare prices
    const cheaperFlexibleOpenPrice = compatiblePrices
      .filter(price => flexibleFares.includes(price.restrictiveFareClass))
      .filter(price => price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].cabinClass === faresServiceClass[0])
      .filter(price => price.totalPrice.$value < totalCurrentPrice)
      .first()

    console.log(faresServiceClass)
    // Get possible cheaper non open prices
    const cheapestSingle = legSolutionIDS.map((legSolutionID, i) => compatiblePrices
      .filter(price => [price.legReferences.legSolutionIDRef].flat().toString() === legSolutionID)
      .filter(price => price.ticketableFares.ticketableFare[0].passengerReferences.passengerReference[0].fareCodes.fareCode[0].cabinClass === faresServiceClass[i])
      .sort((a, b) => a.totalPrice.$value - b.totalPrice.$value)
      .first()
    )
    
    const cheapestSinglePerSolutionIdPrice = cheapestSingle?.reduce((acc, p) => acc + Number(p?.totalPrice?.$value), 0)

    // If we found a cheaperFlexibleOpenPrice, is it cheaper than 1 singles? and the totalCurrentPrice?
    if (
      cheaperFlexibleOpenPrice?.totalPrice?.$value < cheapestSinglePerSolutionIdPrice  && 
      cheaperFlexibleOpenPrice?.totalPrice?.$value < totalCurrentPrice
    ) {
      return { type: 'open', cheaperFlexibleOpenPrice}
    }

    // Is cheapestSingle cheaper than the current price?
    if (
      cheapestSinglePerSolutionIdPrice < totalCurrentPrice
    ) {
      return { type: 'singles', cheapestSinglePerSolutionId: cheapestSingle }
    }

    return null
  }
)

export const selectSelectedItems = createSelector(
  selectPickedLegSolutions,
  selectPickedPrices,
  selectIsReturnJourney,
  (legSolutions, prices, isReturn) => {
      return {
        outward: {
          legSolution: legSolutions[0],
          price: prices[0]
        },
        ...isReturn && {
          return : {
            legSolution: legSolutions[1],
            price: prices[1]
          }
        }
      }
  }
)