import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

// Utils
import { addZeroes } from '../../../utils'

// Styles
import styles from './JourneyList.module.scss'

// Selectors
import { selectCheaperFaresFromSelectedJourneys, selectHomeJourneys } from '../../../selectors/journeys'

// Actions
import { getJourneyResults, setCurrentlyPicking, setDestinationStation, setInboundMoment, setIsReturnJourney, setOutboundMoment, setQuickReturnJourneyPicked } from '../../../actions/journeys'
import { appOpenStationPicker, appOpenJourneyResults, appOpenModal, appCloseModal } from '../../../actions/app'

// Components
import { SecondaryHeader } from '../../theme'

// SVGs
import { ReactComponent as SingleIcon } from '../../../assets/single.svg'
import { ReactComponent as ReturnIcon } from '../../../assets/return.svg'
import { BounceLoader } from 'react-spinners'
import { format } from 'date-fns'
import { push } from 'connected-react-router'
import Modal from '../../Modal/Modal'

export default function JourneyList () {
  const dispatch = useDispatch()

  const journeys = useSelector(selectHomeJourneys)

  const handleQuickJourneyClick = (station, isReturn) => {
    dispatch(setDestinationStation(station))
    dispatch(setOutboundMoment({
      dateTime: new Date(+new Date() + 300000),
      departAfter: true,
      pickerData: {
        date: 'Today',
        hour: format(new Date(+new Date() + 300000), 'H'),
        minute: parseInt(parseInt(new Date(+new Date() + 300000).getMinutes() / 15) * 15).toString().padStart(2, '0'),
        departAfter: true,
        // 5 minutes before now() to get all possible trains
        dateTimeObject: new Date(+new Date() - 300000)
      }
    }))

    if (isReturn){
      dispatch(setIsReturnJourney(isReturn))
      dispatch(setQuickReturnJourneyPicked(isReturn))
      dispatch(push('pre-search'))
    } else {
      dispatch(setCurrentlyPicking(0))
      dispatch(getJourneyResults())
      dispatch(appOpenJourneyResults())
    }
  }

  const handleMoreTicketOptions = () => {
    dispatch(appOpenStationPicker('destination'))
    dispatch(setIsReturnJourney(false))
  }

  const stationlist = journeys.map((station, index) => {
    return (
      <JourneyBar
        key={station.destination}
        station={station}
        singlePrice={station.minPrice}
        returnPrice={station.returnMinPrice}
        onQuickJourneyClick={handleQuickJourneyClick}
      />
    )
  })

  const handleShowMoreInfoModal = useCallback((fareInformation) => {
    dispatch(appOpenModal())

  }, [dispatch])
  const handleCloseModal = () => dispatch(appCloseModal())

  return (  
    <>
      <SecondaryHeader>
        <div className={styles.secondaryHeader}>
          <div className={styles.headings}>
            Travelling to
            <div className={styles.singleText}> One Way</div>
            <div className={styles.returnText}> Return</div>
          </div>
        </div>
      </SecondaryHeader>

      {stationlist}
      <div className={styles.position}>
        <button className={styles.OtherTicketOptions} onClick={handleMoreTicketOptions}>
          Other Destinations
        </button>
      </div>
      <Modal>
        <div className={styles.moreInfoModal}>
          <h1 className={styles.moreInfoTitle}>What can I Buy?</h1>
          <p className={styles.moreInfoText}>
          This eTVM only sells eTickets for use on digital devices and is designed to serve one person/ticket at a time. This eTVM will display a notification if a cheaper ticket is found on the journey selected. 
          </p>
          
          <p className={styles.moreInfoText}>
          Upon completing the transaction, an automated email receipt and duplicate tickets will be sent to the email address associated with the Google or Apple Pay account.
          </p>
          
          <p className={styles.moreInfoText}>
          Tickets that are currently unavailable from this eTVM are; Cross-London, London Travel Cards and all other non eTickets, Season tickets, Rovers and Rangers, multiple passenger tickets, Sleepers and Carnet Tickets. For any of these tickets or advice and assistance, please visit the LNER Travel Centre.
          </p>
          
          <button className={styles.button} onClick={handleCloseModal}>
            Close
          </button>
        </div>
      </Modal>
      <h1 className={styles.eticketWarning}>*Only eTickets available at this eTVM <span onClick={handleShowMoreInfoModal} className={styles.moreInfo}>More Information</span></h1>
    </>
  )
}

function JourneyBar ({ onQuickJourneyClick, station }) {
  return (
    <>
      <li className={styles.journeyBar}>
        <div className={styles.stationName}>{station.name}</div>

        <div className={styles.priceContainer}>
          <div className={classNames(styles.priceBox)} onClick={() => onQuickJourneyClick(station.destination, false)}>
            <SingleIcon />
          </div>

          <div className={classNames(styles.priceBox)} onClick={() => onQuickJourneyClick(station.destination, true)}>
            <ReturnIcon />
          </div>
        </div>
      </li>

      <div className={styles.divider} />
    </>
  )
}
