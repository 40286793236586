import axios from 'axios'

import {
  SET_TOCS
} from './constants'

// Selectors
import { selectAPIUrl, selectAPIToken } from '../selectors/app'

// Data
import localTocs from '../assets/offline-data/tocs.json'
export const setTOCs = (tocs) => (dispatch) => dispatch({
  type: SET_TOCS,
  tocs
})

export const getTOCs = () => (dispatch, getState) => {
  // Until the cache issue is resolved
  return dispatch(setTOCs(localTocs))

  const token = selectAPIToken(getState())
  const url = selectAPIUrl(getState())
  axios.get(`${url}/v1/tocs`, {
    headers: { Authorization: token }
  }).then(({ data }) => {
    dispatch(setTOCs(data))
  })
}
