import * as React from "react"

function WithoutRailcard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-1{fill:#dadada}.prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <g id="prefix__Group_577" data-name="Group 577">
            <path
              className="prefix__cls-1"
              d="M40.55 6.63A24 24 0 006.63 40.55zM43.21 9.62L9.62 43.21A24 24 0 0043.21 9.62z"
            />
            <path
              className="prefix__cls-2"
              d="M33 19.86L19.86 33l4.59 4.59a2.67 2.67 0 003.76 0l9.34-9.34a2.59 2.59 0 00.78-1.88 2.67 2.67 0 00-.78-1.89zM17 30.14L30.14 17l-4.59-4.59a2.61 2.61 0 00-1.88-.77h-9.34a2.66 2.66 0 00-2.66 2.66v9.34a2.67 2.67 0 00.78 1.89zm-.7-15.81a2 2 0 11-2 2 2 2 0 012.03-2z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default WithoutRailcard
