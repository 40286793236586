// This file is a reducer, a simple method that defines
// a initial state for the store and then a switch that
// based on what action is dispatched it will return a brand new state
import * as constants from '../actions/constants'
import { format } from 'date-fns'
export const initialState = {
  originStation: 'GBNCL',
  destinationStation: null,
  passengerAdult: true,
  railcard: null,
  railcardPhase: false,
  outboundMoment: {
    dateTime: new Date(),
    departAfter: true,
    pickerData: {
      date: 'Today',
      hour: format(new Date(), 'H'),
      minute: parseInt(parseInt(new Date().getMinutes() / 15) * 15).toString().padStart(2, '0'),
      departAfter: true,
      dateTimeObject: new Date()
    }
  },
  inboundMoment: {
    dateTime: new Date(+new Date() + 18000000),
    departAfter: true,
    pickerData: {
      date: new Date(+new Date() + 18000000).getDate() === new Date().getDate() ? 'Today' : format(new Date(+new Date() + 18000000), 'd MMMM yyyy'),
      hour: format(new Date(+new Date() + 18000000), 'H'),
      minute: parseInt(parseInt(new Date(+new Date() + 18000000).getMinutes() / 15) * 15).toString().padStart(2, '0'),
      departAfter: true,
      dateTimeObject: new Date(+new Date() + 18000000)
    }
  },
  homeJourneys: [
    {
      name: "London King's Cross",
      destination: 'GBQQK'
    },
    {
      name: "Chester-le-Street",
      destination: 'GBCLS'
    },
    {
      name: 'Darlington',
      destination: 'GBDAR'
    },
    {
      name: 'Durham',
      destination: 'GBDHM'
    },
    {
      name: 'Edinburgh',
      destination: 'GBEDB'
    },
    {
      name: 'Middlesbrough',
      destination: 'GBMBR'
    },
    {
      name: 'Morpeth',
      destination: 'GBMPT'
    },
    {
      name: 'York',
      destination: 'GBQQY'
    }
  ],
  selectedItems: {
    outward: {
      legSolutionID: null,
      priceID: null
    },
    return: {
      legSolutionID: null,
      priceID: null
    }
  },
  currentlyPicking: 0,
  openReturnID: null,
  cheaperFaresFound: null
}

const journey = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_HOME_JOURNEYS:
      return {
        ...state,
        homeJourneys: action.journeys.map(journey => ({
          ...state.homeJourneys.find(h => h.destination === journey.destination),
          ...journey
        }))
      }
    case constants.SET_DESTINATION_STATION:
      return {
        ...state,
        destinationStation: action.station
      }

    case constants.SET_ORIGIN_STATION:
      return {
        ...state,
        originStation: action.station
      }

    case constants.SET_OUTBOUND_MOMENT:
      return {
        ...state,
        outboundMoment: action.moment
      }

    case constants.SET_INBOUND_MOMENT:
      return {
        ...state,
        inboundMoment: action.moment
      }

    case constants.SET_IS_RETURN_JOURNEY:
      return {
        ...state,
        isReturnJourney: action.isReturnJourney
      }

    case constants.SET_IS_LOADING_JOURNEY_RESULTS:
      return {
        ...state,
        isLoadingResults: action.isLoadingResults
      }

    case constants.SET_JOURNEY_RESULTS:
      return {
        ...state,
        results: action.results
      }

    case constants.SET_PASSENGER_ADULT:
      return {
        ...state,
        passengerAdult: action.passenger
      }

    case constants.SET_RAILCARD:
      return {
        ...state,
        railcard: action.railcard
      }

    case constants.SET_RAILCARD_PHASE:
      return {
        ...state,
        railcardPhase: action.railcardPhase
      }

    case constants.SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          ...action.selectedItem
        }
      }
    
    case constants.RESET_SELECTED_ITEM:
      return {
        ...state,
        selectedItems: {
          outward: {
            legSolutionID: null,
            priceID: null
          },
          return: {
            legSolutionID: null,
            priceID: null
          }
        }
      }

    case constants.SET_PICKING_TYPE:
      return {
        ...state,
        currentlyPicking: action.currentlyPicking,
        openReturnID: action.openReturnID
      }

    case constants.SET_NO_JOURNEYS_FOUND:
      return {
        ...state,
        noJourneysFound: action.noJourneysFound
      }
    
    case constants.QUICK_RETURN_JOURNEY_PICKED:
      return {
        ...state,
        quickReturnJourneySelected: action.status
      }
    case constants.SET_CHEAPER_FARES_FOUND:
      return {
        ...state,
        cheaperFaresFound: action.cheaperFares
      }
    default:
      return state
  }
}

export default journey
