import * as React from "react"

function WithRailcard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="1em"
      height="1em"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <circle
            data-name="Ellipse 83"
            cx={24}
            cy={24}
            r={24}
            fill="#008a00"
          />
          <g data-name="local offer-24px-2">
            <path
              data-name="Path 341"
              d="M37.55 24.44l-12-12a2.61 2.61 0 00-1.88-.77h-9.34a2.66 2.66 0 00-2.66 2.66v9.34a2.67 2.67 0 00.78 1.89l12 12a2.67 2.67 0 003.76 0l9.34-9.34a2.59 2.59 0 00.78-1.88 2.67 2.67 0 00-.78-1.9zm-21.22-6.11a2 2 0 112-2 2 2 0 01-2 2z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default WithRailcard
