// App Constants
export const INIT = 'INIT'
export const SET_TOKEN = 'SET_TOKEN'

// Modal Constants
export const SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE'
export const SET_PASSENGER_MODAL_VISIBLE = 'SET_PASSENGER_MODAL_VISIBLE'
export const SET_ROUTE_INFO_MODAL_VISIBLE = 'SET_ROUTE_INFO_MODAL_VISIBLE'
export const SET_RAILCARD_PHASE = 'SET_RAILCARD_PHASE'

// Stations Constants
export const SET_STATIONS = 'SET_STATIONS'

// Railcards Constants
export const SET_RAILCARDS = 'SET_RAILCARDS'

// TOCs Constants

export const SET_TOCS = 'SET_TOCS'

// Journeys Constants
export const SET_HOME_JOURNEYS = 'SET_HOME_JOURNEYS'

export const SET_ORIGIN_STATION = 'SET_ORIGIN_STATION'
export const SET_DESTINATION_STATION = 'SET_DESTINATION_STATION'
export const SET_PASSENGER_ADULT = 'SET_PASSENGER_ADULT'
export const SET_RAILCARD = 'SET_RAILCARD'

export const SET_OUTBOUND_MOMENT = 'SET_OUTBOUND_MOMENT'
export const SET_INBOUND_MOMENT = 'SET_INBOUND_MOMENT'

export const SET_IS_RETURN_JOURNEY = 'SET_IS_RETURN_JOURNEY'

export const SET_NO_JOURNEYS_FOUND = 'SET_NO_JOURNEYS_FOUND'


export const SET_IS_LOADING_JOURNEY_RESULTS = 'SET_IS_LOADING_JOURNEY_RESULTS'

export const SET_JOURNEY_RESULTS = 'SET_JOURNEY_RESULTS'

export const SET_PICKING_TYPE = 'SET_PICKING_TYPE'
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'

export const RESET_SELECTED_ITEM = 'RESET_SELECTED_ITEM'
export const OUTWARD = 0
export const RETURN = 1

export const SET_CHEAPER_FARES_FOUND = 'SET_CHEAPER_FARES_FOUND'
export const QUICK_RETURN_JOURNEY_PICKED = 'QUICK_RETURN_JOURNEY_PICKED'
// Bookings Constants

export const SET_IS_CREATING_BOOKING = 'SET_IS_CREATING_BOOKING'
export const SET_BOOKING = 'SET_BOOKING'

export const SET_ERROR_CREATING_BOOKING = 'SET_ERROR_CREATING_BOOKING'
