import React from 'react'

// Styles
import styles from './NavigationBar.module.scss'

export default function NavigationBar ({ children }) {
  return (
    <div className={styles.navigationBar}>
      {children}
    </div>
  )
}
