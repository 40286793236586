import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { addHours, format, isAfter } from 'date-fns'
import { push } from 'connected-react-router'

// Actions
import { appOpenModal, appCloseModal, appOpenHome, appOpenJourneyResults, passengerOpenModal } from '../../../actions/app'
import { getJourneyResults, setInboundMoment, setIsReturnJourney, setOutboundMoment, setCurrentlyPicking as setLegPicking, setQuickReturnJourneyPicked } from '../../../actions/journeys'

// Selectors
import { selectOriginStation, selectDestinationStation, selectPassengerAdult, selectRailcard, selectIsReturnJourney, selectInboundMoment, selectOutboundMoment, selectQuickReturnJourneyPicked } from '../../../selectors/journeys'

// Components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import DateTimePicker, { possibleHours, possibleMinutes } from '../../DateTimePicker/DateTimePicker'
import Modal from '../../Modal/Modal'
import ArrowForward from '../../../assets/ArrowForward'
import { RoundButton, SecondaryHeader } from '../../theme'
import NavigationBar from '../../NavigationBar/NavigationBar'
import PassengerModal from '../../PassengerModal/PassengerModal'

// Styles
import styles from './PreJourney.module.scss'

// TODO: Refactor this to use a Date object as the source of truth
export default function PreJourney () {
  const history = useHistory()
  const dispatch = useDispatch()

  const outboundMoment = useSelector(selectOutboundMoment)
  const inboundMoment = useSelector(selectInboundMoment)

  const [dateTimes, setDateTimes] = useState({
    outbound: outboundMoment.pickerData,
    return: inboundMoment.pickerData
  })

  const isReturnJourney = useSelector(selectIsReturnJourney)
  const isPickingQuickReturnJourneyDateTime = useSelector(selectQuickReturnJourneyPicked)

  const [currentlyPicking, setCurrentlyPicking] = useState('return')
  const [currentSelectedTab, setCurrentSelectedTab] = useState(isReturnJourney ? 1 : 0)
  const [pickerData, setPickerData] = useState({})

  const handleDatetimePickerChange = (selectedDateTimes = dateTimes[currentlyPicking], departAfter) => {
    let dateTimeObject
    const { date, hour, minute } = selectedDateTimes

    if (date === 'Today') {
      dateTimeObject = new Date(`${format(new Date(), 'd MMMM yyyy')} ${hour}:${minute}`)
    } else {
      dateTimeObject = new Date(`${date} ${new Date().getFullYear()} ${hour}:${minute}`)
    }

    setPickerData({
      date: date,
      hour: hour,
      minute: minute,
      departAfter: departAfter,
      dateTimeObject
    })
  }

  function applyNewDateTime () {
    setDateTimes({ ...dateTimes, [currentlyPicking]: pickerData })
    switch (currentlyPicking) {
      case 'outbound':
        dispatch(setOutboundMoment({
          dateTime: pickerData.dateTimeObject,
          departAfter: pickerData.departAfter,
          pickerData
        }))

        if (!isAfter(dateTimes.return.dateTimeObject, pickerData.dateTimeObject)) {
          const returnPickerData = {
            ...pickerData,
            hour: possibleHours(pickerData.date, pickerData.dateTimeObject, 'return')[0],
            minute: possibleMinutes(pickerData.date, pickerData.dateTimeObject, 'return')[0],
            dateTimeObject: addHours(pickerData.dateTimeObject, 2)
          }
          setDateTimes({ ...dateTimes, [currentlyPicking]: pickerData, return: returnPickerData })

          dispatch(setInboundMoment({
            dateTime: returnPickerData.dateTimeObject,
            departAfter: pickerData.departAfter,
            pickerData: returnPickerData
          }))
        }
        break

      case 'return':
        dispatch(setInboundMoment({
          dateTime: pickerData.dateTimeObject,
          departAfter: pickerData.departAfter,
          pickerData
        }))
        if (isPickingQuickReturnJourneyDateTime) {
          handleSearchForJourneys()
        }
        break
      default:
    }

    setPickerData({})
    dispatch(appCloseModal())
  }

  const openModal = useCallback((setPicking) => {
    setPickerData(dateTimes[setPicking])
    setCurrentlyPicking(setPicking)
    dispatch(appOpenModal())
  }, [setPickerData, setCurrentlyPicking,dispatch, dateTimes])
  
  function closeModal () {
    dispatch(appCloseModal())
  }

  function openPassengerModal () {
    dispatch(passengerOpenModal())
  }

  const adultState = useSelector(selectPassengerAdult)
  const selectedRailcard = useSelector(selectRailcard)

  const originStation = useSelector(selectOriginStation)
  const destinationStation = useSelector(selectDestinationStation)

  const handleGoHome = () => {
    // Close the modal in case it's open
    dispatch(appCloseModal())
    dispatch(appOpenHome())
  }

  const handleGoBack = () => history.goBack()

  const handleSearchForJourneys = () => {
    dispatch(setQuickReturnJourneyPicked(false))
    dispatch(setLegPicking(0))
    dispatch(getJourneyResults())
    dispatch(appOpenJourneyResults())
  }

  const handleOneWayOrReturnChange = (index) => {
    setCurrentSelectedTab(index)
    dispatch(setIsReturnJourney(index === 1))
  }

  const handleSelectStation = (where) => {
    dispatch(push('station-picker', where))
  }


  useEffect(() => {
    if (isPickingQuickReturnJourneyDateTime) {
      openModal('return')
    }
  }, [isPickingQuickReturnJourneyDateTime, openModal])

  return (
    <>
      <Modal>
        <DateTimePicker
          selectedDateTime={dateTimes[currentlyPicking]}
          departAfter={dateTimes[currentlyPicking].departAfter}
          onChange={handleDatetimePickerChange}
          currentlyPicking={currentlyPicking}
          minDate={dateTimes.outbound.dateTimeObject}
        />
        <NavigationBar>
          <RoundButton type='home' size='large' onClick={handleGoHome} />
          <RoundButton type='back' size='large' onClick={closeModal} />
          {/* TODO: Export the icon to SVG and follow the same approach as the other types of RoundButton */}
          <RoundButton type='cancel' size='large' onClick={closeModal} />
          <RoundButton type='confirm' size='large' onClick={applyNewDateTime} />
        </NavigationBar>
      </Modal>
      <PassengerModal />
      <SecondaryHeader>
        <header>
          <span className={styles.stationName}>{originStation.label}</span>
          <ArrowForward className={styles.arrow} arrowFill='white' />
          <span className={styles.stationName}>{destinationStation.label}</span>
        </header>
      </SecondaryHeader>
      <Tabs defaultIndex={currentSelectedTab} onSelect={handleOneWayOrReturnChange} className={styles.tabsContainer}>
        <TabList className={styles.tabList}>
          <Tab className={styles.tab} selectedClassName={styles.selected}>One way</Tab>
          <Tab className={styles.tab} selectedClassName={styles.selected}>Return</Tab>
        </TabList>
        <div className={styles.tabsRibbon} />
        <TabPanel>
          <ul className={styles.oddEvenDestinationList}>
            <li onClick={() => handleSelectStation('origin')}><label>FROM:</label>{originStation.label}</li>
            <li onClick={() => handleSelectStation('destination')}><label>TO:</label>{destinationStation.label}</li>
            <li onClick={() => openModal('outbound')}><label>OUTBOUND:</label>{dateTimes.outbound.date + ', ' + dateTimes.outbound.hour + ':' + dateTimes.outbound.minute}</li>
            <li onClick={() => openPassengerModal()}><label>PASSENGER:</label>{adultState === true ? 'Adult' : 'Child'} Fare, {selectedRailcard === null ? 'no railcard' : selectedRailcard}{' '}</li>
          </ul>
        </TabPanel>
        <TabPanel>
          <ul className={styles.oddEvenDestinationList}>
            <li onClick={() => handleSelectStation('origin')}><label>FROM:</label>{originStation.label}</li>
            <li onClick={() => handleSelectStation('destination')}><label>TO:</label>{destinationStation.label}</li>
            <li onClick={() => openModal('outbound')}><label>OUTBOUND:</label>{dateTimes.outbound.date + ', ' + dateTimes.outbound.hour + ':' + dateTimes.outbound.minute}</li>
            <li onClick={() => openModal('return')}><label>RETURN:</label>{dateTimes.return.date + ', ' + dateTimes.return.hour + ':' + dateTimes.return.minute}</li>
            <li onClick={() => openPassengerModal()}><label>PASSENGER:</label>{adultState === true ? 'Adult' : 'Child'} Fare, {selectedRailcard === null ? 'no railcard' : selectedRailcard}{' '}</li>
          </ul>
        </TabPanel>
      </Tabs>
      <NavigationBar>
        <RoundButton type='home' size='large' onClick={handleGoHome} />
        <RoundButton type='back' size='large' onClick={handleGoBack} />
        <button className={styles.findTicketsButton} onClick={handleSearchForJourneys}>Find tickets</button>
      </NavigationBar>
    </>
  )
}
