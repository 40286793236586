import { createSelector } from 'reselect'

export const selectStations = (state) => state.stations.list || []

export const selectMainStations = createSelector(selectStations,
  (stations) => stations.filter(station => station.type !== 'OUTPUT_ONLY')
)

export const selectStationByCode = (code) => createSelector(
  selectStations,
  (stations) => stations.find(item => item.value === code)
)
